<template>
  <div class="item-wrapper">
    <div class="title">
      {{ title }}
    </div>
    <div class="wrapper">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Wrapper',
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
// scss
.item-wrapper {
  padding: 20px;
  margin: 10px 0;
  // background: #F5F7FB;
  border-radius: 4px;
}
.title {
  border-left: 3px solid #008075;
  line-height: 24px;
  font-size: 20px;
  margin-bottom: 20px;
  padding-left: 10px;
}
.wrapper {
  margin-bottom: 20px;
}
</style>