<template>
  <div>
    <CardWrap title="医生收入概况">
      <div class="doctor-wrap">
        <div class="grid-content bg-purple">
          <LineBarChart
            :options="doctorIncomeOpt"
            class="doctorIncome"
          />
        </div>
        <div class="grid-content bg-purple-light">
          <!-- 表格 -->
          <el-table
            ref="table"
            v-loading="loading"
            height="380"
            header-row-class-name="table-header"
            class="list-table"
            :data="tableData"
            stripe
            highlight-current-row
          >
            <el-table-column
              align="center"
              width="80"
              prop="rank"
              label="收入排名"
            />
            <el-table-column
              align="center"
              show-overflow-tooltip
              prop="name"
              label="医生姓名"
            />
            <el-table-column
              align="center"
              width="180"
              prop="accumulativeIncome"
              label="累计收入(人民币收入）"
            >
              <template slot-scope="scoped">
                {{ `${scoped.row.accumulativeIncome}（${scoped.row.accumulativeRmbIncome}）` }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              width="190"
              prop="lastMonthIncome"
              label="上月收入(上月人民币收入)"
            >
              <template slot-scope="scoped">
                {{ `${scoped.row.lastMonthIncome}（${scoped.row.lastMonthRmbIncome}）` }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="giftCount"
              label="积分余额"
            />
            <el-table-column
              align="center"
              width="80"
              label="所属厂家"
            >
              <template slot-scope="scoped">
                <el-button
                  type="text"
                  size="small"
                  @click="onCheck(scoped.row.doctorId)"
                >
                  查看
                </el-button>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              width="180"
              show-overflow-tooltip
              prop="department"
              label="所属科室"
            />
            <el-table-column
              align="center"
              min-width="180"
              show-overflow-tooltip
              prop="hospital"
              label="所属医院"
            />
          </el-table>
        </div>
      </div>
    </CardWrap>

    <!-- 查看 -->
    <el-dialog
      title="所属厂家"
      :visible.sync="editForm.dialogVisible"
      width="40%"
      @closed="onClosed"
    >
      <el-table
        ref="table"
        v-loading="checkLoading"
        class="list-table"
        border
        :data="checkTableData"
        stripe
        highlight-current-row
      >
        <el-table-column
          align="center"
          prop="topCompanyName"
          label="企业名称"
        />
        <el-table-column
          align="center"
          prop="factoryName"
          label="厂商名称"
        />
      </el-table>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          @click="editForm.dialogVisible = false"
        >关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getDoctorIncomeRank, getHeadAverageTrend, getDoctorFactory } from '@/apis/risk-control'
export default {
  name: 'DoctorIncome',
  data() {
    return {
      loading: false,
      tableData: [],
      checkLoading: false,
      checkTableData: [],
      editForm: {
        dialogVisible: false
      },
      doctorIncomeOpt: {
        title: {
          text: '医生月平均收入'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        }
      }
    }
  },
  mounted() {
    this.getDataList()
    this.getTopicData(4, 'doctorIncomeOpt')
  },
  methods: {
    // 获取列表数据 获取医生收入排名
    async getDataList() {
      try {
        this.loading = true
        const res = await getDoctorIncomeRank()
        this.loading = false
        this.tableData = res
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    },
    // 头部平均数变化趋势 /重复视频，重复题目，月平均收入
    async getTopicData(type, targetDataName) {
      try {
        const res = await getHeadAverageTrend({ type })
        // 处理数据为折线图
        const typeMap = {
          'average': '所有医生平均收入',
          'head': '头部5%平均收入'
        }
        this.formatOpt({ rootData: res, typeMap, targetDataName, type: 'bar' })
      } catch (error) {
        console.log(error)
      }
    },
    // 查看厂家
    async onCheck(row) {
      try {
        this.editForm.row = row
        this.editForm.dialogVisible = true
        this.checkLoading = true
        const res = await getDoctorFactory({doctorId: row})
        this.checkLoading = false
        this.checkTableData = res
      } catch (error) {
        this.checkLoading = false
      }

    },
    // 关闭
    onClosed() {
      this.editForm.dialogVisible = false
    },
    /**
         * 格式化折线图数据
         * rootData 接口返回的根数据（Array）
         * typeMap 映射数据 （Object）
         * targetDataName 目标数据名 （String）
         **/
    formatOpt({ rootData = [], typeMap, targetDataName, type = 'line' }) {
      try {
        let xArr = []
        let seriesArr = []
        for (const key in typeMap) {
          const obj = { name: typeMap[key], type, data: [] }
          xArr = rootData.map(currentValue => {
            obj.data.push(currentValue[key])
            return currentValue.dateStr
          })
          seriesArr = [...seriesArr, obj]
        }
        this.$set(this[targetDataName], 'xAxis', { data: xArr })
        this.$set(this[targetDataName], 'series', seriesArr)
      } catch (error) {
        console.log('formatOpt', error)
      }

    }
  }

}
</script>

<style lang="scss" scoped>
// scss
.doctor-wrap {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  gap: 30px;
  .grid-content {
    height: 420px;
    box-shadow: 2px 2px 4px 0 rgba(234, 236, 240, 0.5);
    border-radius: 4px;
    background-color: #fff;
    padding: 20px 18px;
    box-sizing: border-box;
    .doctorIncome {
      width: 100%;
      height: 100%;
    }
    :deep(.table-header) th {
      background-color: #eff0f2;
      color: #272e40;
    }
  }
}

</style>
