<template>
  <!-- 风控管理-场景管理 -->
  <div class="page-main">
    <!-- 功能按钮区 -->
    <div class="btn-wrap">
      <div class="title">
        {{ $route.meta.title }}
      </div>
    </div>
    <!-- 搜索区域 -->
    <SearchWrap>
      <el-form
        v-model="search"
        inline
        label-suffix="："
      >
        <el-form-item
          label="目标ID"
          prop="targetId"
        >
          <el-input
            v-model="search.targetId"
            clearable
            placeholder="请输入目标ID"
          />
        </el-form-item>
        <el-form-item
          label="目标类型"
          prop="targetType"
        >
          <el-select v-model="search.targetType" clearable placeholder="请选择">
            <el-option
              v-for="item in targetTypeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          label="目标状态"
          prop="reviewStatus"
        >
          <el-select v-model="search.reviewStatus" clearable placeholder="请选择">
            <el-option
              v-for="item in statusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            plain
            @click="queryData"
          >
            搜索
          </el-button>
        </el-form-item>
      </el-form>
    </SearchWrap>
    <!-- 处理表格区 -->
    <!-- 表格 -->
    <el-table
      ref="table"
      v-loading="loading"
      class="list-table"
      :data="tableData"
      border
      stripe
      highlight-current-row
      @sort-change="onSortChange"
    >
      <el-table-column
        prop="sceneId"
        label="场景ID"
      />
      <el-table-column
        prop="targetName"
        label="目标名"
      />
      <el-table-column
        label="目标ID"
        prop="targetId"
      />
      <el-table-column
        prop="mobile"
        label="目标手机号"
      />
      <el-table-column
        prop="targetTypeStr"
        label="目标类型"
      />
      <el-table-column
        prop="reviewStatus"
        label="目标状态"
      >
        <template slot-scope="scoped">
          {{ scoped.row.reviewStatus === 0?'待审核':scoped.row.reviewStatus === 1?'审核通过':scoped.row.reviewStatus === 2?'审核拒绝':'' }}
        </template>
      </el-table-column>
      <el-table-column
        prop="specialUserRemark"
        label="备注"
        :show-overflow-tooltip="true"
      />
      <el-table-column
        prop="specialUserReason"
        label="客户端原因"
        :show-overflow-tooltip="true"
      />
      <el-table-column
        prop="createdAt"
        label="创建时间"
      >
        <template
          v-if="scoped.row.createdAt"
          slot-scope="scoped"
        >
          {{ formatDate(scoped.row.createdAt) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="updatedAt"
        label="更新时间"
      >
        <template
          v-if="scoped.row.updatedAt"
          slot-scope="scoped"
        >
          {{ formatDate(scoped.row.updatedAt) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="specialUserEndAt"
        label="有效期至"
      >
        <template
          v-if="scoped.row.specialUserEndAt"
          slot-scope="scoped"
        >
          {{ formatDate(scoped.row.specialUserEndAt) }}
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        width="150"
        align="center"
      >
        <template slot-scope="scoped">
          <template v-if="scoped.row.reviewStatus !== 0">
            <el-button
              type="primary"
              size="mini"
              plain
              @click="onLook(scoped.row,'3')"
            >
              查看
            </el-button>
          </template>
          <!-- eslint-disable-next-line -->
          <template v-else slot-scope="scoped">
            <el-button
              type="warning"
              size="mini"
              plain
              @click="onThaw(scoped.row,'1')"
            >
              解冻
            </el-button>
            <el-button
              type="danger"
              size="mini"
              plain
              @click="onReject(scoped.row,'2')"
            >
              拒绝
            </el-button>
          </template>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination-wrap">
      <el-pagination
        center
        :current-page="pagination.pageNo"
        :total="pagination.total"
        :page-size="pagination.pageSize"
        :page-sizes="pagination.sizes"
        :layout="pagination.layout"
        :hide-on-single-page="false"
        background
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      />
    </div>
    <!-- 弹窗区 -->

    <!-- 操作弹窗 -->
    <el-dialog
      :title="editTitle"
      :visible.sync="editForm.dialogVisible"
      width="60%"
      @closed="onClosed"
    >
      <el-form
        :model="editForm"
        label-suffix="："
        label-width="120px"
      >
        <el-row>
          <el-col :span="10">
            <el-form-item
              label="用户名称"
              prop="targetName"
            >
              <span>{{ editForm.targetName }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item
              label="用户手机号"
              prop="mobile"
            >
              <span>{{ editForm.mobile }}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="10">
            <el-form-item
              label="上次冻结时间"
              prop="lastRiskControlAt"
            >
              <span>{{ formatDate(editForm.lastRiskControlAt) }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="14">
            <el-form-item
              label="冻结原因"
              prop="specialUserReason"
            >
              <span>{{ editForm.specialUserReason }}</span>
            </el-form-item>
          </el-col>

          <el-row>
            <el-col :span="10">
              <el-form-item
                label="后台备注"
                prop="specialUserRemark"
              >
                <span>{{ editForm.specialUserRemark }}</span>
              </el-form-item>
            </el-col>
            <el-col v-if="editForm.rejectReason" :span="14">
              <el-form-item
                label="拒绝原因"
                prop="rejectReason"
              >
                <span>{{ editForm.rejectReason }}</span>
              </el-form-item>
            </el-col>
          </el-row>
        </el-row>
        <el-row v-if="editForm.otherImageList&&editForm.otherImageList.length">
          <el-form-item label="客户端图片" prop="otherImageList">
            <template v-for="(item,index) in editForm.otherImageList">
              <el-col :key="index" :span="6">
                <el-image
                  v-if="item"
                  class="img-certification"
                  :src="item"
                  :preview-src-list="editForm.otherImageList"
                />
              </el-col>
            </template>
          </el-form-item>
        </el-row>

        <el-form

          ref="editForm"
          :model="editForm"
          label-suffix="："
          label-position="top"
          :rules="editFormRules"
        >
          <template v-if="reasonRequired">
            <el-form-item
              label="原因（给客户端）"
              prop="rejectReason"
            >
              <el-input
                v-model.trim="editForm.rejectReason"
                type="textarea"
                class="textarea auto-width"
                :autosize="{ minRows: 3, maxRows: 6}"
                placeholder="请输入原因"
              />
            </el-form-item>
          </template>
          <template v-if="imgRequired || showImg">
            <el-row>
              <el-col :span="10">
                <el-form-item
                  label="支付宝查询证明"
                  prop="zhifubaoObj.url"
                >
                  <ImageOnlyUpload
                    ref="zhifubaoImage"
                    :disabled="popType === '3'"
                    :init-custom-cover-url="editForm.zhifubaoObj?editForm.zhifubaoObj.middle:''"
                    @uploadSuccess="zhifubaoSuccess"
                  />
                </el-form-item>
              </el-col><el-col :span="10">
                <el-form-item
                  label="医师认证查询证明"
                  prop="yishizhengObj.url"
                >
                  <ImageOnlyUpload
                    ref="yishiImage"
                    :disabled="popType === '3'"
                    :init-custom-cover-url="editForm.yishizhengObj?editForm.yishizhengObj.middle:''"
                    @uploadSuccess="yishizhengSuccess"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </template>
        </el-form>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="editForm.dialogVisible = false">取 消</el-button>
        <el-button
          v-if="popType!=='3'"
          type="primary"
          :loading="editForm.loading"
          @click="editFormSubmit"
        >确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import paginationMixin from '@/mixins/pagination'
import { blockRecordList, blockRecorddetail, reviewBlockRecord, specialLevelList, specialTargetList } from '@/apis/risk-control'
export default {
  name: 'SpeciaUser',
  mixins: [paginationMixin],
  data() {
    return {
      search: {
        targetId: '',
        reviewStatus: '',
        targetType: ''
      },
      statusList: [
        {
          label: '待审核',
          value: '0'
        },
        {
          label: '审核通过',
          value: '1'
        },
        {
          label: '审核拒绝',
          value: '2'
        }
      ],
      popType: '3',

      loading: false,
      tableData: [],
      targetTypeList: [],
      //  目标等级
      levelList: [],
      editForm: {},
      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date())
          }
        }, {
          text: '昨天',
          onClick(picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24)
            picker.$emit('pick', date)
          }
        }, {
          text: '一周前',
          onClick(picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', date)
          }
        }]
      }
    }
  },
  computed: {
    editTitle() {
      return this.popType === '1' ? '解冻' : this.popType === '2' ? '拒绝' : '查看'
    },
    imgRequired() {
      return this.popType === '1'
    },
    reasonRequired() {
      return this.popType === '2'
    },
    showImg() {
      return this.editForm?.zhifubaoObj?.middle || this.editForm?.yishizhengObj?.middle
    },
    editFormRules() {
      return {
        rejectReason: [{ required: this.reasonRequired, message: '请输入原因', trigger: 'blur' }],
        'zhifubaoObj.url': [{ required: this.imgRequired, message: '请上传支付宝查询证明', trigger: 'blur' }],
        'yishizhengObj.url': [{ required: this.imgRequired, message: '请上传医师认证查询证明', trigger: 'blur' }]
      }
    }
  },
  mounted() {
    this.getDataList()
    this.getTargetTypeList()
    this.getLevelList()
  },
  methods: {
    // 获取详情
    async getDetail(id) {

      try {
        const params = {recordId: id}
        const data = await blockRecorddetail(params)
        this.onSetEditForm(data)
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    },
    // 获取列表数据
    async getDataList() {
      try {
        const params = this.getParams(this.search)
        this.loading = true
        const { data, totalCount } = await blockRecordList(params)
        this.loading = false
        const formatData = data.map(item => {
          item.endAt = item.endAt ? this.$dayjs(item.endAt).format('YYYY-MM-DD HH:mm:ss') : ''
          return item
        })
        this.tableData = formatData
        this.pagination.total = totalCount
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    },
    // 获取等级列表
    async getLevelList() {
      try {
        const res = await specialLevelList()
        this.levelList = res
      } catch (error) {
        console.log(error)
      }
    },
    // 风控目标列表
    async getTargetTypeList() {
      try {
        const res = await specialTargetList()
        this.targetTypeList = res
      } catch (error) {
        console.log(error)
      }
    },
    zhifubaoSuccess(res) {
      let obj = {
        url: res.imageName,
        unBlockProofType: 1,
        ...res
      }
      this.editForm.zhifubaoObj = {...this.editForm.zhifubaoObj, ...obj}
    },
    yishizhengSuccess(res) {
      let obj = {
        url: res.imageName,
        unBlockProofType: 0,
        ...res
      }

      this.editForm.yishizhengObj = {...this.editForm.yishizhengObj, ...obj}
    },
    // 表单提交
    editFormSubmit() {
      this.$refs['editForm'].validate(async valid => {
        if (valid) {
          this.editForm.loading = true
          let predObj = {
            id: this.editForm.id,
            userId: this.editForm.userId,
            userType: this.editForm.userType,
            targetId: this.editForm.targetId,
            specialType: this.editForm.specialType,
            adminId: this.editForm.adminId,
            reviewStatus: this.popType * 1,
            rejectReason: this.editForm.rejectReason,
            proofList: this.editForm.otherImage
          }

          const params = this.imgRequired ? Object.assign({}, predObj, {proofList: [...this.editForm.otherImage, this.editForm.zhifubaoObj, this.editForm.yishizhengObj]}) : predObj
          try {
            const res = await reviewBlockRecord(params)
            if (res == true) {
              this.getDataList()
              this.$message({ type: 'success', message: `${this.editTitle}成功` })
            }
            this.editForm.loading = false
            this.editForm.dialogVisible = false
          } catch (error) {
            console.log(error)
            this.editForm.loading = false
            this.$message({
              type: 'error',
              message: error.message
            })
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 搜索
    queryData() {
      this.pagination = this.$options.data().pagination
      this.getDataList()
    },
    onSetEditForm(row) {

      let tartgetObj = JSON.parse(JSON.stringify(row))
      let zhifubaoObj = {}
      let yishizhengObj = {}
      let otherImage = []
      let otherImageList = []
      for (let item of tartgetObj.proofList || []) {
        if (item.unBlockProofType === 1) {
          zhifubaoObj = {...item, middle: item.url}
        } else if (item.unBlockProofType === 0) {
          yishizhengObj = {...item, middle: item.url}
        } else {
          otherImage.push(item)
          otherImageList.push(item.url)
        }
      }
      this.editForm = { ...row, zhifubaoObj, yishizhengObj, otherImage, otherImageList, dialogVisible: true }
      console.log(this.editForm)
    },
    // 查看
    onLook(row, type) {
      this.popType = type
      this.getDetail(row.id)
    },
    // 解冻
    onThaw(row, type) {
      this.popType = type
      this.getDetail(row.id)
    },
    // 拒绝
    onReject(row, type) {
      this.popType = type
      this.getDetail(row.id)
    },
    onClosed() {
      this.resetForm()
    },
    resetForm() {
      this.editForm = this.$options.data().editForm
      this.$refs.editForm.resetFields()
    },

    /**
		 * html 页面渲染处理
		 */
    // 格式化渲染时间
    formatDate(date) {
      return date ? this.$dayjs(date).format('YYYY-MM-DD HH:mm:ss') : ''
    }
  }
}
</script>

<style lang="scss" scoped>
// scss
.btn-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  .title {
    font-size: 22px;
    font-weight: 500;
  }
}
.handle-btn {
  margin-bottom: 10px;
}
.textarea {
  width: 400px;
}
.auto-width {
  width: 100%;
}
.pagination-wrap {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
.img-certification {
  width: 146px;
  height: 146px;
  background: #f1f1f1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #666;
  margin-bottom: 40px;
}
</style>
