<template>
  <!-- 风控管理-场景管理 -->
  <div class="page-main">
    <!-- 基础概况 -->
    <BasicOverview ref="basicOverview" />
    <!-- 医生收入概况 -->
    <DoctorIncome ref="doctorIncome" />
    <!-- 厂商风险监控 -->
    <RiskMonitoring ref="riskMonitoring" />
  </div>
</template>

<script>
import BasicOverview from './components/BasicOverview'
import DoctorIncome from './components/DoctorIncome'
import RiskMonitoring from './components/RiskMonitoring'
export default {
  name: 'ScreenControl',
  components: { DoctorIncome, BasicOverview, RiskMonitoring },
  data() {
    return {
      tableData: []
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
// scss
.page-main {
  background: #f5f7fb;
  padding: 20px;
  box-sizing: border-box;
}
</style>