<template>
  <!-- 风控管理-场景管理 -->
  <div class="page-main">
    <!-- 功能按钮区 -->
    <div class="btn-wrap">
      <div class="title">
        {{ $route.meta.title }}
      </div>
      <!-- <el-button type="primary" icon="el-icon-plus" plain @click="onAdd">新增</el-button> -->
    </div>
    <!-- 搜索区域 -->
    <SearchWrap>
      <el-form
        v-model="search"
        inline
        label-suffix="："
      >
        <el-form-item
          label="手机号"
          prop="mobile"
        >
          <el-input
            v-model.trim="search.mobile"
            placeholder="请输入手机号"
          />
        </el-form-item>
        <el-form-item
          label="目标名"
          prop="userName"
        >
          <el-input
            v-model.trim="search.userName"
            placeholder="请输入目标名"
          />
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            plain
            @click="queryData"
          >
            搜索
          </el-button>
        </el-form-item>
      </el-form>
    </SearchWrap>
    <!-- 处理表格区 -->
    <div class="handle-btn">
      <el-button
        type="primary"
        icon="el-icon-plus"
        plain
        @click="onAdd"
      >
        新增
      </el-button>
    </div>
    <!-- 表格 -->
    <el-table
      ref="table"
      v-loading="loading"
      class="list-table"
      :data="tableData"
      border
      stripe
      highlight-current-row
      @sort-change="onSortChange"
    >
      <el-table-column
        prop="sceneId"
        label="场景ID"
      />
      <el-table-column
        prop="userName"
        label="目标名"
      />
      <el-table-column
        label="目标ID"
        prop="targetId"
      />
      <el-table-column
        prop="mobile"
        label="目标手机号"
      />
      <el-table-column
        prop="targetTypeStr"
        label="目标类型"
      />
      <el-table-column
        prop="type"
        label="目标状态"
      >
        <template slot-scope="scoped">
          {{ scoped.row.type === 0?'黑名单':scoped.row.type === 1?'局部白名单':scoped.row.type === 2?'全局白名单':scoped.row.type === 3?'登陆受限制':'' }}
        </template>
      </el-table-column>
      <el-table-column
        prop="remark"
        label="备注"
        :show-overflow-tooltip="true"
      />
      <el-table-column
        prop="reason"
        label="客户端原因"
        :show-overflow-tooltip="true"
      />
      <el-table-column
        prop="endAt"
        label="有效期至"
      >
        <template
          v-if="scoped.row.endAt"
          slot-scope="scoped"
        >
          {{ formatDate(scoped.row.endAt) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="createdAt"
        width="200"
        label="创建时间"
      >
        <template
          v-if="scoped.row.createdAt"
          slot-scope="scoped"
        >
          {{ formatDate(scoped.row.createdAt) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="updatedAt"
        width="200"
        label="更新时间"
      >
        <template
          v-if="scoped.row.updatedAt"
          slot-scope="scoped"
        >
          {{ formatDate(scoped.row.updatedAt) }}
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        width="150"
        align="center"
      >
        <template slot-scope="scoped">
          <el-button
            type="warning"
            size="mini"
            plain
            @click="onEdit(scoped.row)"
          >
            编辑
          </el-button>
          <el-button
            type="danger"
            size="mini"
            plain
            @click="onDel(scoped.row)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination-wrap">
      <el-pagination
        center
        :current-page="pagination.pageNo"
        :total="pagination.total"
        :page-size="pagination.pageSize"
        :page-sizes="pagination.sizes"
        :layout="pagination.layout"
        :hide-on-single-page="false"
        background
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      />
    </div>
    <!-- 弹窗区 -->

    <!-- 编辑和新增 -->
    <el-dialog
      :title="editTitle"
      :visible.sync="editForm.dialogVisible"
      width="60%"
      @closed="onClosed"
    >
      <el-form
        ref="editForm"
        :model="editForm"
        label-suffix="："
        label-width="100px"
        :rules="editFormRules"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item
              label="目标ID"
              prop="targetId"
            >
              <el-input
                v-model.trim="editForm.targetId"
                placeholder="请输入目标ID"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="场景ID"
              prop="sceneId"
            >
              <el-input
                v-model.trim="editForm.sceneId"
                placeholder="请输入场景ID"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="目标类型"
              prop="targetType"
            >
              <el-select
                v-model="editForm.targetType"
                class="auto-width"
                placeholder="请选择目标类型"
              >
                <el-option
                  v-for="item in targetTypeList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="等级"
              prop="level"
            >
              <el-select
                v-model="editForm.level"
                class="auto-width"
                placeholder="请选择等级"
              >
                <el-option
                  v-for="item in levelList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              label="有效期"
              prop="endAt"
            >
              <el-date-picker
                v-model="editForm.endAt"
                default-time="23:59:59"
                type="datetime"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="选择日期时间"
                align="right"
                :picker-options="pickerOptions"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              label="类型"
              prop="type"
            >
              <el-radio-group v-model="editForm.type">
                <el-radio :label="0">
                  黑名单
                </el-radio>
                <el-radio :label="1">
                  局部白名单
                </el-radio>
                <el-radio :label="2">
                  全局白名单
                </el-radio>
                <el-radio :label="3">
                  登陆受限制
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              label="备注"
              prop="remark"
            >
              <el-input
                v-model.trim="editForm.remark"
                type="textarea"
                class="textarea auto-width"
                :autosize="{ minRows: 3, maxRows: 6}"
                placeholder="请输入备注"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              label="原因（给客户端）"
              prop="reason"
            >
              <el-input
                v-model.trim="editForm.reason"
                type="textarea"
                class="textarea auto-width"
                :autosize="{ minRows: 3, maxRows: 6}"
                placeholder="请输入原因"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="editForm.dialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          :loading="editForm.loading"
          @click="editFormSubmit"
        >确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import paginationMixin from '@/mixins/pagination'
import { specialUserList, saveSpecialUser, deleteSpecialUser, specialLevelList, specialTargetList } from '@/apis/risk-control'
export default {
  name: 'SpeciaUser',
  mixins: [paginationMixin],
  data() {
    return {
      search: {
        userName: '',
        mobile: ''
      },
      loading: false,
      tableData: [],
      targetTypeList: [],
      //  目标等级
      levelList: [],
      editForm: {
        id: '',
        endAt: '',
        targetType: '',
        type: 0,
        sceneId: '',
        level: '',
        remark: '',
        reason: '',
        loading: false,
        dialogVisible: false
      },
      editFormRules: {
        targetType: [{ required: true, message: '请选择目标类型', trigger: 'blur' }],
        type: [{ required: true, message: '请选择类型', trigger: 'blur' }],
        sceneId: [{ required: false, message: '请输入场景ID', trigger: 'blur' }],
        level: [{ required: true, message: '请选择等级', trigger: 'blur' }],
        remark: [{ required: true, message: '请输入备注', trigger: 'blur' }],
        reason: [{ required: true, message: '请输入原因', trigger: 'blur' }],
        endAt: [{ required: false, message: '请选择日期', trigger: 'blur' }],
        targetId: [{ required: true, message: '请输入目标ID', trigger: 'blur' }]
      },
      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date())
          }
        }, {
          text: '昨天',
          onClick(picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24)
            picker.$emit('pick', date)
          }
        }, {
          text: '一周前',
          onClick(picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', date)
          }
        }]
      }
    }
  },
  computed: {
    editTitle() {
      return this.editForm.id ? '编辑' : '新增'
    }
  },
  mounted() {
    this.getDataList()
    this.getTargetTypeList()
    this.getLevelList()
  },
  methods: {
    // 获取列表数据
    async getDataList() {
      try {
        const params = this.getParams(this.search)
        this.loading = true
        const { data, totalCount } = await specialUserList(params)
        this.loading = false
        const formatData = data.map(item => {
          item.endAt = item.endAt ? this.$dayjs(item.endAt).format('YYYY-MM-DD HH:mm:ss') : ''
          return item
        })
        this.tableData = formatData
        this.pagination.total = totalCount
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    },
    // 获取等级列表
    async getLevelList() {
      try {
        const res = await specialLevelList()
        this.levelList = res
      } catch (error) {
        console.log(error)
      }
    },
    // 风控目标列表
    async getTargetTypeList() {
      try {
        const res = await specialTargetList()
        this.targetTypeList = res
      } catch (error) {
        console.log(error)
      }
    },
    // 表单提交
    editFormSubmit() {
      this.$refs['editForm'].validate(async valid => {
        if (valid) {
          this.editForm.loading = true
          const params = Object.assign({}, this.editForm)
          try {
            const res = await saveSpecialUser(params)
            let msg = ''
            if (res == true) {
              this.getDataList()
              msg = params.id ? '编辑' : '新增'
              this.$message({ type: 'success', message: `${msg}成功` })
            }
            this.editForm.loading = false
            this.editForm.dialogVisible = false
          } catch (error) {
            console.log(error)
            this.editForm.loading = false
            this.$message({
              type: 'error',
              message: error.message
            })
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 搜索
    queryData() {
      this.pagination = this.$options.data().pagination
      this.getDataList()
    },
    // 新增
    onAdd() {
      this.editForm = this.$options.data().editForm
      this.editForm.formTitle = '新增'
      this.editForm.dialogVisible = true
    },
    // 查看
    onCheck({ id, name }) {
      this.$router.push({
        name: 'SceneCheck',
        query: { id, title: name }
      })
    },
    // 编辑
    onEdit(row) {
      this.editForm = { ...row, dialogVisible: true }
    },
    // 删除
    onDel(row) {
      this.$confirm(`此操作将删除[${row.userName || ''}]这条信息, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await deleteSpecialUser({ id: row.id })
        if (res) {
          this.getDataList()
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        }
      })
    },
    onClosed() {
      this.resetForm()
    },
    resetForm() {
      this.editForm = this.$options.data().editForm
      this.$refs.editForm.resetFields()
    },

    /**
		 * html 页面渲染处理
		 */
    // 格式化渲染时间
    formatDate(date) {
      return date ? this.$dayjs(date).format('YYYY-MM-DD HH:mm:ss') : ''
    }
  }
}
</script>

<style lang="scss" scoped>
// scss
.btn-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  .title {
    font-size: 22px;
    font-weight: 500;
  }
}
.handle-btn {
  margin-bottom: 10px;
}
.textarea {
  width: 400px;
}
.auto-width {
  width: 100%;
}
.pagination-wrap {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
</style>
