<template>
  <!-- 风控管理-场景管理 -->
  <div class="page-main">
    <!-- 功能按钮区 -->
    <div class="btn-wrap">
      <div class="title">
        {{ $route.meta.title }}
      </div>
      <el-button
        type="primary"
        icon="el-icon-arrow-left"
        size="small"
        plain
        @click="$router.back()"
      >
        返回
      </el-button>
      <!-- <el-button type="primary" icon="el-icon-plus" plain @click="onAdd">新增</el-button> -->
    </div>
    <!-- 搜索区域 -->
    <SearchWrap>
      <el-form
        v-model="search"
        inline
        label-suffix="："
      >
        <el-form-item
          label="医生ID"
          prop="doctorId"
        >
          <el-input
            v-model.trim="search.doctorId"
            placeholder="请输入医生ID"
          />
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            plain
            @click="queryData"
          >
            搜索
          </el-button>
        </el-form-item>
      </el-form>
    </SearchWrap>
    <!-- 处理表格区 -->
    <!-- <div class="handle-btn">
            <el-button type="primary" size="small" icon="el-icon-plus" plain @click="onAdd">新增</el-button>
        </div> -->
    <!-- 表格 -->
    <el-table
      ref="table"
      v-loading="loading"
      class="list-table"
      :data="tableData"
      border
      stripe
      highlight-current-row
      @sort-change="onSortChange"
    >
      <el-table-column
        prop="doctorNameReal"
        label="医生姓名"
      />
      <el-table-column
        prop="title"
        label="场景描述"
      />
      <el-table-column
        prop="changeType"
        label="变动类型"
      />
      <el-table-column
        prop="giftCount"
        label="变动值"
      />
      <el-table-column
        prop="giftType"
        label="获取方式"
      />
      <el-table-column
        prop="commodityName"
        label="商品名称"
      />
      <el-table-column
        prop="sourceName"
        label="变动来源"
      />
      <el-table-column
        prop="updatedAt"
        width="200"
        label="更新时间"
      >
        <template
          v-if="scoped.row.updatedAt"
          slot-scope="scoped"
        >
          {{ $dayjs(scoped.row.updatedAt).format('YYYY-MM-DD HH:mm:ss') }}
        </template>
      </el-table-column>
      <el-table-column
        prop="createdAt"
        width="200"
        label="创建时间"
      >
        <template
          v-if="scoped.row.createdAt"
          slot-scope="scoped"
        >
          {{ $dayjs(scoped.row.createdAt).format('YYYY-MM-DD HH:mm:ss') }}
        </template>
      </el-table-column>
      <!-- <el-table-column label="操作" width="150" align="center">
                <template slot-scope="scoped">
                    <el-button type="warning" size="mini" plain @click="onEdit(scoped.row)">编辑</el-button>
                    <el-button type="danger" size="mini" plain @click="onDel(scoped.row)">删除</el-button>
                </template>
            </el-table-column> -->
    </el-table>
    <div class="pagination-wrap">
      <el-pagination
        center
        :current-page="pagination.pageNo"
        :total="pagination.total"
        :page-size="pagination.pageSize"
        :page-sizes="pagination.sizes"
        :layout="pagination.layout"
        :hide-on-single-page="false"
        background
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      />
    </div>

    <!-- 弹窗区 -->

    <!-- 编辑和新增 -->
    <el-dialog
      :title="editTitle"
      :visible.sync="editForm.dialogVisible"
      width="40%"
      @closed="onClosed"
    >
      <el-form
        ref="editForm"
        :model="editForm"
        label-suffix="："
        size="small"
        :rules="editFormRules"
      >
        <el-form-item
          label="用户ID"
          prop="userId"
        >
          <el-input
            v-model="editForm.userId"
            style="width: 400px;"
            placeholder="请选择用户ID"
          />
        </el-form-item>
        <el-form-item
          label="用户类型"
          prop="userType"
        >
          <el-select
            v-model="editForm.userType"
            style="width: 400px;"
            placeholder="请选择用户类型"
          >
            <el-option
              v-for="item in userTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          label="有效期"
          prop="endAt"
        >
          <el-date-picker
            v-model="editForm.endAt"
            default-time="23:59:59"
            style="width: 400px;"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择日期时间"
            align="right"
            :picker-options="pickerOptions"
          />
        </el-form-item>
        <el-form-item
          label="用户状态"
          prop="type"
        >
          <el-radio-group v-model="editForm.type">
            <el-radio :label="0">
              黑名单
            </el-radio>
            <el-radio :label="1">
              局部白名单
            </el-radio>
            <el-radio :label="2">
              全局白名单
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="备注"
          prop="reason"
        >
          <el-input
            v-model="editForm.reason"
            type="textarea"
            style="width: 400px;"
            class="textarea"
            :autosize="{ minRows: 3, maxRows: 6}"
            placeholder="请输入备注"
          />
        </el-form-item>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="editForm.dialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          :loading="editForm.loading"
          @click="editFormSubmit"
        >确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import paginationMixin from '@/mixins/pagination'
import { giftRecordList, saveSpecialUser, deleteSpecialUser } from '@/apis/risk-control'
export default {
  name: 'GiftRecord',
  mixins: [paginationMixin],
  data() {
    return {
      search: {
        doctorId: ''
      },
      loading: false,
      tableData: [],
      userTypeList: [{
        value: 1,
        label: '医生'
      }, {
        value: 2,
        label: '代表'
      }, {
        value: 3,
        label: '厂家'
      }, {
        value: 4,
        label: '患者'
      }],
      editForm: {
        id: '',
        endAt: '',
        userType: 1,
        type: 0,
        reason: '',
        loading: false,
        dialogVisible: false
      },
      editFormRules: {
        userType: [{ required: true, message: '请选择用户类型', trigger: 'blur' }],
        type: [{ required: true, message: '请选择用户状态', trigger: 'blur' }],
        reason: [{ required: true, message: '请输入原因', trigger: 'blur' }],
        endAt: [{ required: true, message: '请选择日期', trigger: 'blur' }],
        userId: [{ required: true, message: '请输入用户ID', trigger: 'blur' }]
      },
      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date())
          }
        }, {
          text: '昨天',
          onClick(picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24)
            picker.$emit('pick', date)
          }
        }, {
          text: '一周前',
          onClick(picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', date)
          }
        }]
      }
    }
  },
  computed: {
    editTitle() {
      return this.editForm.id ? '编辑' : '新增'
    }
  },
  mounted() {
    this.search.doctorId = this.$route.query.id
    this.getDataList()
  },
  methods: {
    // 获取列表数据
    async getDataList() {
      try {
        const params = this.getParams(this.search)
        this.loading = true
        const { data, totalCount } = await giftRecordList(params)
        this.loading = false
        const formatData = data.map(item => {
          item.updatedAt = item.updatedAt ? this.$dayjs(item.updatedAt).format('YYYY-MM-DD HH:mm:ss') : ''
          item.createdAt = item.createdAt ? this.$dayjs(item.createdAt).format('YYYY-MM-DD HH:mm:ss') : ''
          return item
        })
        this.tableData = formatData
        this.pagination.total = totalCount
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    },
    // 表单提交
    editFormSubmit() {
      this.$refs['editForm'].validate(async valid => {
        if (valid) {
          this.editForm.loading = true
          const params = Object.assign({}, this.editForm)
          try {
            const res = await saveSpecialUser(params)
            let msg = ''
            if (res == true) {
              this.getDataList()
              msg = params.id ? '编辑' : '新增'
              this.$message({ type: 'success', message: `${msg  }成功` })
            }
            this.editForm.loading = false
            this.editForm.dialogVisible = false
          } catch (error) {
            console.log(error)
            this.editForm.loading = false
            this.$message({
              type: 'error',
              message: error.message
            })
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 搜索
    queryData() {
      this.pagination = this.$options.data().pagination
      this.getDataList()
    },
    // 新增
    onAdd() {
      this.editForm = this.$options.data().editForm
      this.editForm.formTitle = '新增'
      this.editForm.dialogVisible = true
    },
    // 查看
    onCheck({ id, name }) {
      this.$router.push({
        name: 'SceneCheck',
        query: { id, title: name }
      })
    },
    // 编辑
    onEdit(row) {
      this.editForm = { ...row, dialogVisible: true }
    },
    // 删除
    onDel(row) {
      this.$confirm(`此操作将删除[${row.userName || ''}]这条信息, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await deleteSpecialUser({ id: row.id })
        if (res) {
          this.getDataList()
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        }
      })
    },
    onClosed() {
      this.resetForm()
    },
    resetForm() {
      this.editForm = this.$options.data().editForm
      this.$refs.editForm.resetFields()
    }
  }
}
</script>

<style lang="scss" scoped>
// scss
.btn-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  .title {
    font-size: 22px;
    font-weight: 500;
  }
}
.handle-btn {
  margin-bottom: 10px;
}
.textarea {
  width: 400px;
}
.pagination-wrap {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
</style>