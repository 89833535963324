<template>
  <div>
    <!-- 功能按钮区 -->
    <div class="btn-wrap">
      <div class="title">
        {{ $route.meta.title }}
      </div>
      <el-button
        type="primary"
        icon="el-icon-arrow-left"
        size="small"
        plain
        @click="$router.back()"
      >
        返回
      </el-button>
    </div>
    <!-- 搜索区域 -->
    <search-wrap>
      <el-form
        v-model="search"
        inline
        label-suffix="："
      >
        <el-form-item
          v-if="father.name"
          prop="name"
          label="场景名称"
        >
          <el-tooltip
            effect="dark"
            content="点击可复制"
            placement="top"
          >
            <el-button
              size="small"
              type="primary"
              @click="handleCopyLink(father.name)"
            >
              {{ father.name }}
            </el-button>
          </el-tooltip>
        </el-form-item>
        <el-form-item
          v-if="father.code"
          prop="code"
          label="code"
        >
          <el-tooltip
            effect="dark"
            content="点击可复制"
            placement="top"
          >
            <el-button
              size="small"
              type="primary"
              @click="handleCopyLink(father.code)"
            >
              {{ father.code }}
            </el-button>
          </el-tooltip>
        </el-form-item>
        <el-form-item
          label="状态"
          prop="status"
        >
          <el-select
            v-model="search.status"
            filterable
            placeholder="请选择状态"
          >
            <el-option
              v-for="item in statusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            plain
            @click="queryData"
          >
            搜索
          </el-button>
        </el-form-item>
      </el-form>
    </search-wrap>

    <!-- 处理表格区 -->
    <HandleBtnWrap>
      <div slot="left">
        <el-button
          type="primary"
          icon="el-icon-plus"
          plain
          @click="onAdd"
        >
          新增
        </el-button>
      </div>
    </HandleBtnWrap>
    <!-- 表格 -->
    <el-table
      ref="table"
      v-loading="loading"
      class="list-table"
      :data="tableData"
      border
      stripe
      highlight-current-row
      @sort-change="onSortChange"
    >
      <el-table-column
        prop="drl"
        label="规则"
        :show-overflow-tooltip="true"
      />
      <el-table-column
        prop="tag"
        label="标签"
      />
      <el-table-column
        prop="status"
        label="状态"
        width="100"
      >
        <template slot-scope="scoped">
          {{ scoped.row.status === 0?'未启用':scoped.row.status === 1?'局部启用':scoped.row.status === 2?'全启用':'' }}
        </template>
      </el-table-column>
      <el-table-column
        prop="version"
        label="版本号"
        width="100"
      />

      <el-table-column
        label="操作"
        width="380"
        align="center"
      >
        <template slot-scope="scoped">
          <template>
            <el-button
              v-if="scoped.row.status !== 1"
              type="primary"
              size="mini"
              plain
              @click="onUpAndDOwn(scoped.row,1)"
            >
              测试启用
            </el-button>
            <el-button
              v-if="scoped.row.status !== 2"
              type="primary"
              size="mini"
              plain
              @click="onUpAndDOwn(scoped.row,2)"
            >
              启用
            </el-button>
            <el-button
              v-if="scoped.row.status !== 0"
              type="danger"
              size="mini"
              plain
              @click="onUpAndDOwn(scoped.row,0)"
            >
              停用
            </el-button>
            <el-button
              type="warning"
              size="mini"
              plain
              @click="onEdit(scoped.row,scoped.$index)"
            >
              编辑
            </el-button>
            <!-- <el-button type="success" size="mini" plain @click="onCheck(scoped.row)">查看</el-button> -->
            <el-button
              type="danger"
              size="mini"
              plain
              @click="onDel(scoped.row)"
            >
              删除
            </el-button>
          </template>
        </template>
      </el-table-column>
    </el-table>
    <!-- 弹窗区 -->

    <!-- 编辑和新增 -->
    <el-dialog
      :title="editTitle"
      :visible.sync="dialogVisible"
      width="70%"
      lock-scroll
      @closed="resetForm"
    >
      <el-form
        ref="editForm"
        :model="editForm"
        :disabled="editForm.disabled"
        label-suffix="："
        label-width="100px"
        label-position="right"
        :rules="editFormRules"
      >
        <el-row>
          <el-form-item
            label="标签"
            prop="tag"
          >
            <el-col :span="12">
              <el-input
                v-model.trim="editForm.tag"
                :maxlength="50"
                placeholder="请输入标签"
              />
            </el-col>
          </el-form-item>
          <el-form-item
            label="描述信息"
            prop="description"
          >
            <el-col :span="12">
              <el-input
                v-model.trim="editForm.description"
                :rows="4"
                type="textarea"
                :maxlength="1000"
                placeholder="请输入描述信息"
              />
            </el-col>
          </el-form-item>
          <!-- <el-col>
                        <el-form-item label="状态" prop="status">
                            <el-radio-group v-model="editForm.status">
                                <el-radio :label="0">未启用</el-radio>
                                <el-radio :label="1">局部启用</el-radio>
                                <el-radio :label="2">全启用</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col> -->
          <el-col>
            <el-form-item
              label="规则"
              prop="drl"
            >
              <el-button
                size="small"
                @click="showDeffCode = !showDeffCode"
              >
                {{ showDeffCode?'编辑':'预览变更' }}
              </el-button>
              <div style="height: 500px; width: 100%;">
                <CodeMirror
                  v-if="!showDeffCode&&dialogVisible"
                  ref="coder"
                  v-model="editForm.drl"
                  :readonly="editForm.disabled"
                  class="in-coder-panel"
                />
                <DeffCode
                  v-if="showDeffCode"
                  :new-val="editForm.drl"
                  :old-val="oldVal"
                />
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          :loading="editForm.loading"
          @click="editFormSubmit"
        >确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import paginationMixin from '@/mixins/pagination'
import { saveRule, sceneRuleList, changeRuleStatus, deleteRule } from '@/apis/risk-control'
export default {
  name: 'CheckList',
  mixins: [paginationMixin],
  data() {
    return {
      search: {
        status: '',
        sceneId: '',
        tabletitle: ''
      },
      loading: false,
      tableData: [],
      dialogVisible: false,
      showDeffCode: false, // 展示对比
      oldVal: '',
      father: {
        name: '',
        code: ''
      },
      statusList: [{
        value: '',
        label: '全部'
      }, {
        value: 0,
        label: '未启用'
      }, {
        value: 1,
        label: '局部启用'
      }, {
        value: 2,
        label: '全启用'
      }],
      editForm: {
        sceneId: '',
        tag: '',
        id: '',
        drl: '',
        status: '',
        name: '',
        description: '',
        loading: false,
        disabled: false
      },
      editFormRules: {
        drl: [{ required: true, message: '请输入规则', trigger: 'blur' }],
        status: [{ required: false, message: '请选择状态', trigger: 'blur' }],
        tag: [{ required: true, message: '请输入标签', trigger: 'blur' }],
        description: [{ required: true, message: '请输入描述信息', trigger: 'blur' }]
      }
    }
  },
  computed: {
    editTitle() {
      return this.editForm.id ? '编辑' : '新增'
    }
  },
  mounted() {
    const { id, name, code } = this.$route.query
    this.search.sceneId = id

    this.father = { name, code }
    this.getDataList()
  },
  methods: {
    // 获取列表数据
    async getDataList() {
      try {
        const params = this.getParams(this.search)
        this.loading = true
        const { data, totalCount } = await sceneRuleList(params)
        this.loading = false
        this.tableData = data
        this.pagination.total = totalCount
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    },
    // 表单提交
    editFormSubmit() {
      this.$refs['editForm'].validate(async valid => {
        if (valid) {
          this.editForm.loading = true
          const params = Object.assign({}, this.editForm)
          params.sceneId = this.search.sceneId
          try {
            const res = await saveRule(params)
            if (res == true) {
              this.getDataList()
              this.$message({
                type: 'success',
                message: '编辑成功！'
              })
            }
            this.editForm.loading = false
            this.dialogVisible = false
          } catch (error) {
            console.log(error)
            this.editForm.loading = false
            this.$message({
              type: 'error',
              message: error.message
            })
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 搜索
    queryData() {
      this.pagination = this.$options.data().pagination
      this.getDataList()
    },
    // 新增
    onAdd() {
      this.editForm = this.$options.data().editForm
      this.dialogVisible = true
    },
    // 查看
    onCheck(item) {
      this.editForm = { ...item, disabled: true }
      this.dialogVisible = true
    },
    // 编辑
    onEdit(item, index) {
      this.oldVal = this.tableData[index + 1]?.drl || ''
      this.editForm = { ...item }
      this.dialogVisible = true
    },
    // 停用、启用、内部测试
    async onUpAndDOwn(row, status) {
      const { id } = row
      const res = await changeRuleStatus({ id, status })
      const message = status === 0 ? '停用' : status === 1 ? '内部测试' : status === 2 ? '启用' : ''
      if (res) {
        this.$message({ type: 'success', message: `${message  }成功！` })
        this.getDataList()
      }
    },
    // 删除
    onDel(row) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await deleteRule({ id: row.id })
        if (res) {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          this.getDataList()
        }
      })
    },
    // 重置表单
    resetForm() {
      this.editForm = this.$options.data().editForm
      this.$refs.editForm.resetFields()
    },
    // 复制文本
    handleCopyLink(val) {
      // console.log(row)
      const _this = this
      this.$copyText(val).then(function() {
        _this.$message({
          message: `复制成功:${  val}`,
          type: 'success'
        })
      }, function() {
        _this.$message({
          showClose: true,
          message: '复制失败，请手动复制',
          type: 'error'
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
// scss
.preview {
  width: 30px;
  height: 30px;
  object-fit: cover;
  zoom: 0.6;
}
.btn-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  .title {
    font-size: 22px;
    font-weight: 500;
  }
}
.handle-btn {
  margin-bottom: 10px;
}
.in-coder-panel {
  width: 900px;
  height: 400px;
}
.pagination-wrap {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
</style>