<template>
  <div class="item">
    <div class="card-title">
      {{ label }}
    </div>
    <div>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardItem',
  props: {
    label: {
      type: String,
      default: '标题'
    }
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
// scss
.item {
  margin: 20px 0;
  margin-left: 30px;
  .card-title {
    margin-bottom: 10px;
    position: relative;
    &::after {
      content: '';
      width: 7px;
      height: 7px;
      position: absolute;
      left: -15px;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 50%;
      background-color: #ddd;
    }
  }
}
</style>