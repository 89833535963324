<template>
  <!-- 风控管理-场景管理 -->
  <Wrapper
    class="page-main"
    title="医生积分变动记录"
  >
    <el-table
      ref="table"
      v-loading="loading"
      class="list-table"
      :data="tableData"
      border
      stripe
      highlight-current-row
    >
      <el-table-column
        prop="doctorNameReal"
        label="医生姓名"
      />
      <el-table-column
        prop="title"
        label="场景描述"
      />
      <el-table-column
        prop="changeType"
        label="变动类型"
      />
      <el-table-column
        prop="giftCount"
        label="变动值"
      />
      <el-table-column
        prop="giftType"
        label="获取方式"
      />
      <el-table-column
        prop="commodityName"
        label="商品名称"
      />
      <el-table-column
        prop="sourceName"
        label="变动来源"
      />
      <el-table-column
        prop="updatedAt"
        width="200"
        label="更新时间"
      >
        <template
          v-if="scoped.row.updatedAt"
          slot-scope="scoped"
        >
          {{ $dayjs(scoped.row.updatedAt).format('YYYY-MM-DD HH:mm:ss') }}
        </template>
      </el-table-column>
      <el-table-column
        prop="createdAt"
        width="200"
        label="创建时间"
      >
        <template
          v-if="scoped.row.createdAt"
          slot-scope="scoped"
        >
          {{ $dayjs(scoped.row.createdAt).format('YYYY-MM-DD HH:mm:ss') }}
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination-wrap">
      <el-pagination
        center
        :current-page="pagination.pageNo"
        :total="pagination.total"
        :page-size="pagination.pageSize"
        :page-sizes="pagination.sizes"
        :layout="pagination.layout"
        :hide-on-single-page="false"
        background
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      />
    </div>
  </Wrapper>
</template>
<script>
import paginationMixin from '@/mixins/pagination'
import { giftRecordList } from '@/apis/risk-control'
import Wrapper from './Wrapper'
export default {
  name: 'Relevant',
  components: { Wrapper },
  mixins: [paginationMixin],
  data() {
    return {
      search: {
        doctorId: ''
      },
      loading: false,
      tableData: [],
      userTypeList: [{
        value: 1,
        label: '医生'
      }, {
        value: 2,
        label: '代表'
      }, {
        value: 3,
        label: '厂家'
      }, {
        value: 4,
        label: '患者'
      }],
      editForm: {
        id: '',
        endAt: '',
        userType: 1,
        type: 0,
        reason: '',
        loading: false,
        dialogVisible: false
      },
      editFormRules: {
        userType: [{ required: true, message: '请选择用户类型', trigger: 'blur' }],
        type: [{ required: true, message: '请选择用户状态', trigger: 'blur' }],
        reason: [{ required: true, message: '请输入原因', trigger: 'blur' }],
        endAt: [{ required: true, message: '请选择日期', trigger: 'blur' }],
        userId: [{ required: true, message: '请输入用户ID', trigger: 'blur' }]
      },
      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date())
          }
        }, {
          text: '昨天',
          onClick(picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24)
            picker.$emit('pick', date)
          }
        }, {
          text: '一周前',
          onClick(picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', date)
          }
        }]
      }
    }
  },
  mounted() {
    this.search.doctorId = this.$route.query.userId
    this.getDataList()
  },
  methods: {
    // 获取列表数据
    async getDataList() {
      try {
        const params = this.getParams(this.search)
        this.loading = true
        const { data, totalCount } = await giftRecordList(params)
        this.loading = false
        const formatData = data.map(item => {
          item.updatedAt = item.updatedAt ? this.$dayjs(item.updatedAt).format('YYYY-MM-DD HH:mm:ss') : ''
          item.createdAt = item.createdAt ? this.$dayjs(item.createdAt).format('YYYY-MM-DD HH:mm:ss') : ''
          return item
        })
        this.tableData = formatData
        this.pagination.total = totalCount
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
// scss
.handle-btn {
  margin-bottom: 10px;
}
.textarea {
  width: 400px;
}
.pagination-wrap {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
</style>