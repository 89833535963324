<template>
  <div class="page-main">
    <!-- 功能按钮区 -->
    <div class="btn-wrap">
      <div class="title">
        {{ $route.meta.title }}
      </div>
      <el-button
        type="primary"
        icon="el-icon-arrow-left"
        size="small"
        plain
        @click="$router.back()"
      >
        返回
      </el-button>
    </div>
    <ContextData />
    <Relevant />
    <CostDetail />
  </div>
</template>

<script>
import ContextData from './components/ContextData'
import CostDetail from './components/CostDetail'
import Relevant from './components/Relevant'
export default {
  name: 'DealDealList',
  components: { ContextData, CostDetail, Relevant },
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
//scss
.btn-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  .title {
    font-size: 22px;
    font-weight: 500;
  }
}
</style>