<template>
  <div>
    <CardWrap title="厂商风险监控">
      <div class="wrap">
        <div class="search-wrap">
          <span class="label">共{{ pagination.total }}个厂家</span>
          <div class="right-form">
            <!-- <el-input v-model="search.name" placeholder="搜索直播名称" class="input-with-select">
                            <el-button slot="append" icon="el-icon-search" />
                        </el-input> -->
          </div>
        </div>
        <!-- 表格 -->
        <el-table
          ref="table"
          v-loading="loading"
          border
          class="list-table"
          :data="tableData"
          @sort-change="onSortChange"
        >
          <el-table-column
            prop="doctorNameReal"
            label="基础数据"
            width="800"
            align="center"
          >
            <el-table-column
              prop="name"
              label="厂商名称"
              align="center"
            />
            <el-table-column
              prop="giftCount"
              label="余额"
              align="center"
            />
            <el-table-column
              prop="accumulativeChargeGift"
              label="累计充值金额"
              align="center"
            />
          </el-table-column>
          <el-table-column
            prop="certify"
            label="医生真实度"
            sortable="custom"
            align="center"
          >
            <el-table-column
              prop="realNameRating"
              label="实名认证率"
              align="center"
            >
              <template slot-scope="scoped">
                {{ toPercent(scoped.row.realNameRating) }}
              </template>
            </el-table-column>
            <el-table-column
              prop="certifyRating"
              label="身份认证率"
              align="center"
            >
              <template slot-scope="scoped">
                {{ toPercent(scoped.row.certifyRating) }}
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column
            prop="income"
            label="医生月均收入"
            sortable="custom"
            align="center"
          >
            <el-table-column
              prop="incomeAverage"
              align="center"
              label="均值"
            >
              <template slot-scope="scoped">
                {{ scoped.row.incomeAverage }}元
              </template>
            </el-table-column>
            <el-table-column
              prop="incomeHead"
              label="头部5%均值"
              align="center"
            >
              <template slot-scope="scoped">
                {{ scoped.row.incomeHead }}元
              </template>
            </el-table-column>
            <el-table-column
              prop="incomeTop"
              label="最高值"
              align="center"
            >
              <template slot-scope="scoped">
                {{ scoped.row.incomeTop }}元
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column
            prop="video_repetition"
            label="视频重复学习频次"
            sortable="custom"
            align="center"
          >
            <el-table-column
              prop="videoRepetitionAverage"
              label="均值"
              align="center"
            >
              <template slot-scope="scoped">
                {{ scoped.row.videoRepetitionAverage }}次
              </template>
            </el-table-column>
            <el-table-column
              prop="videoRepetitionHead"
              label="头部5%均值"
              align="center"
            >
              <template slot-scope="scoped">
                {{ scoped.row.videoRepetitionHead }}次
              </template>
            </el-table-column>
            <el-table-column
              prop="videoRepetitionTop"
              label="最高值"
              align="center"
            >
              <template slot-scope="scoped">
                {{ scoped.row.videoRepetitionTop }}次
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column
            prop="exam_repetition"
            label="题目重复回答频次"
            sortable="custom"
            align="center"
          >
            <el-table-column
              prop="examRepetitionAverage"
              label="均值"
              align="center"
            >
              <template slot-scope="scoped">
                {{ scoped.row.examRepetitionAverage }}次
              </template>
            </el-table-column>
            <el-table-column
              prop="examRepetitionHead"
              label="头部5%均值"
              align="center"
            >
              <template slot-scope="scoped">
                {{ scoped.row.examRepetitionHead }}次
              </template>
            </el-table-column>
            <el-table-column
              prop="examRepetitionTop"
              label="最高值"
              align="center"
            >
              <template slot-scope="scoped">
                {{ scoped.row.examRepetitionTop }}次
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column
            prop="liveTimeAverage"
            label="直播平均时长"
            sortable="custom"
            width="150"
            align="center"
          >
            <template slot-scope="scoped">
              {{ changetime(scoped.row.liveTimeAverage) }}
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination-wrap">
          <el-pagination
            center
            :current-page="pagination.pageNo"
            :total="pagination.total"
            :page-size="pagination.pageSize"
            :page-sizes="pagination.sizes"
            :layout="pagination.layout"
            :hide-on-single-page="false"
            background
            @size-change="onSizeChange"
            @current-change="onCurrentChange"
          />
        </div>
      </div>
    </CardWrap>
  </div>
</template>

<script>
import paginationMixin from '@/mixins/pagination'
import { getOrgRiskData } from '@/apis/risk-control'
export default {
  name: 'RiskMonitoring',
  mixins: [paginationMixin],
  data() {
    return {
      loading: false,
      tableData: [],
      search: {
        name: '',
        orderBy: 'video_repetition',
        asc: true
      },
      pagination: {
        pageSize: 20
      }
    }
  },
  mounted() {
    this.getDataList()
  },
  methods: {
    // 厂家风险监控
    async getDataList() {
      try {
        this.loading = true
        const params = this.getParams(this.search)
        const res = await getOrgRiskData(params)
        this.loading = false
        const { data, totalCount } = res
        this.tableData = data
        this.pagination.total = totalCount
      } catch (error) {
        this.loading = false
      }
    },
    // 排序
    onSortChange({ prop, order }) {
      this.search.asc = order === 'ascending'
      if (prop === 'liveTimeAverage') {
        this.search.orderBy = 'live_time'
      } else {
        this.search.orderBy = prop
      }
      this.getDataList()
    },
    // 小数转百分数
    toPercent(value) {
      return `${parseFloat((value * 100).toPrecision(12))}%`
    },
    // 秒转分
    changetime(value) {
      if (value === 0) return ''
      let secondTime = parseInt(value)// 秒
      let minuteTime = 0// 分
      let hourTime = 0// 小时
      if (secondTime > 60) { // 如果秒数大于60，将秒数转换成整数
        // 获取分钟，除以60取整数，得到整数分钟
        minuteTime = parseInt(secondTime / 60)
        // 获取秒数，秒数取佘，得到整数秒数
        secondTime = parseInt(secondTime % 60)
        // 如果分钟大于60，将分钟转换成小时
        if (minuteTime > 60) {
          // 获取小时，获取分钟除以60，得到整数小时
          hourTime = parseInt(minuteTime / 60)
          // 获取小时后取佘的分，获取分钟除以60取佘的分
          minuteTime = parseInt(minuteTime % 60)
        }
      }
      let time = `${parseInt(secondTime)}秒`
      if (minuteTime > 0) {
        time = `${parseInt(minuteTime)}分${time}`
      }
      if (hourTime > 0) {
        time = `${parseInt(hourTime)}小时${time}`
      }
      return time
    }
  }
}
</script>

<style lang="scss" scoped>
//scss
.wrap {
  padding: 25px 33px;
  box-sizing: border-box;
  border-radius: 4px;
  background: #fff;
  .search-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  :deep(.table-header) th {
    background: #eff0f2;
  }
  // 修改排序icon
  :deep(.list-table) {
    .caret-wrapper {
      width: 34px;
      .sort-caret.ascending {
        left: 18px;
        &::after {
          content: '';
          width: 2px;
          height: 5px;
          background-color: #c0c4cc;
          position: absolute;
          top: 5px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      .sort-caret.descending {
        &::after {
          content: '';
          width: 2px;
          height: 5px;
          background-color: #c0c4cc;
          position: absolute;
          top: -10px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
    .descending .sort-caret.descending,
    .ascending .sort-caret.ascending {
      &::after {
        background-color: #008075;
      }
    }
  }
  .pagination-wrap {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
}
</style>
