<template>
  <!-- 风控管理-风控记录 -->
  <Wrapper
    class="page-main"
    title="医生的讲课费明细"
  >
    <!-- 表格 -->
    <el-table
      ref="table"
      v-loading="loading"
      class="list-table"
      :data="tableData"
      border
      stripe
      highlight-current-row
    >
      <el-table-column
        prop="title"
        label="标题"
      />
      <el-table-column
        prop="amount"
        label="金额"
        width="100"
      />
      <el-table-column
        prop="detailType"
        label="收支"
        width="110"
      >
        <template slot-scope="scoped">
          {{ scoped.row.detailType===1?'收入':scoped.row.detailType===2?'支出':'' }}
        </template>
      </el-table-column>
      <el-table-column
        prop="orgId"
        label="付款组织ID"
        width="110"
      />
      <el-table-column
        prop="orgName"
        label="付款组织名"
        width="230"
      />
      <el-table-column
        prop="payTime"
        width="160"
        label="支付时间"
      />
    </el-table>
    <div class="pagination-wrap">
      <el-pagination
        center
        :current-page="pagination.pageNo"
        :total="pagination.total"
        :page-size="pagination.pageSize"
        :page-sizes="pagination.sizes"
        :layout="pagination.layout"
        :hide-on-single-page="false"
        background
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      />
    </div>
  </Wrapper>
</template>

<script>
import paginationMixin from '@/mixins/pagination'
import { speakerFeeList } from '@/apis/risk-control'
import Wrapper from './Wrapper'
export default {
  name: 'CostDetail',
  components: { Wrapper },
  mixins: [paginationMixin],
  data() {
    return {
      search: {
        id: '',
        detailType: ''
      },
      loading: false,
      tableData: [],
      sceneList: [],
      editForm: {
        id: '',
        remark: '',
        loading: false,
        dialogVisible: false
      },
      editFormRules: {
        remark: [{ required: true, message: '请输入备注', trigger: 'blur' }]
      }
    }
  },
  mounted() {
    this.search.doctorId = this.$route.query.id
    this.getDataList()
  },
  methods: {
    // 获取列表数据
    async getDataList() {
      try {
        const params = this.getParams(this.search)
        this.loading = true
        const { data, totalCount } = await speakerFeeList(params)
        this.loading = false
        this.tableData = data
        this.pagination.total = totalCount
      } catch (error) {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
// scss
.pagination-wrap {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
</style>