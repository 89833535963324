<template>
  <div>
    <!-- 风控大屏-基础概况 -->
    <div class="page-main">
      <!-- 基础概况 -->
      <CardWrap
        title="基础概况"
        class="basis"
      >
        <div class="header-wrap">
          <div class="grid-content bg-purple">
            <div class="title">
              {{ toFixed(baseData.realNameRating*100) || 0 }}%
            </div>
            <div class="sub-title">
              <div class="label-type">
                医生真实度(完成实名认证医生占比)
              </div>
              <div>
                <span class="label">总数:<span class="label-content"><span class="highlight">{{ toFixed(baseData.doctorCount) }}人</span></span></span>
                <span class="label">工作证明通过率:<span class="label-content"><span class="highlight">{{ Number(baseData.doctorCertifyRating)*100 || 0 }}%</span></span></span>
                <!-- <span class="label">身份认证:<span class="label-content">{{ toFixed(baseData.doctorCertifyRating) }}人</span></span>
                                <span class="label">实名认证:<span class="label-content">{{ toFixed(baseData.realNameRating) }}人</span></span> -->
              </div>
            </div>
          </div>
          <div class="grid-content bg-purple">
            <div class="title">
              {{ toFixed(baseData.videoRepetitionCount) }}次
            </div>
            <div class="sub-title">
              <div class="label-type">
                同视频传递频次(科会同视频同医师每月学习频次)
              </div>
              <!-- <span class="label">厂商平均视频量:<span class="label-content">10个</span></span> -->
              <!-- <span class="label">单视频被关联项目:<span class="label-content">52个</span></span> -->
            </div>
          </div>
          <div class="grid-content bg-purple">
            <div class="title">
              {{ toFixed(baseData.surveyRepetitionCount) }}次
            </div>
            <div class="sub-title">
              <div class="label-type">
                调研重复频次(单题目同医生每月回答频次)
              </div>
              <!-- <span class="label">观众平均观看时长:<span class="label-content">5分钟</span></span>
                            <span class="label">答题准确率:<span class="label-content">60%</span></span> -->
            </div>
          </div>
          <div class="grid-content bg-purple">
            <div class="title">
              {{ toFixed(baseData.liveAverageTime/60) }}分钟
            </div>
            <div class="sub-title">
              <div class="label-type">
                直播平均时长(上个月)
              </div>
              <div>
                <span class="label">观众平均观看时长:<span class="label-content"><span class="highlight">{{ toFixed(baseData.liveViewAverageTime/60) }}分钟</span></span></span>
                <!-- <span class="label">答题准确率:<span class="label-content">60%</span></span> -->
              </div>
            </div>
          </div>
        </div>
      </CardWrap>
      <!-- 基础概况折线图 -->
      <CardWrap class="chart">
        <div class="row-bg">
          <div class="grid-content bg-purple">
            <LineBarChart :options="doctorOpt" />
          </div>
          <div class="grid-content bg-purple-light">
            <LineBarChart :options="videoOpt" />
          </div>
          <div class="grid-content bg-purple">
            <LineBarChart :options="topicOpt" />
          </div>
          <div class="grid-content bg-purple">
            <LineBarChart :options="liveOpt" />
          </div>
          <div class="grid-content bg-purple">
            <LineBarChart :options="riskOpt" />
          </div>
        </div>
      </CardWrap>
    </div>
  </div>
</template>

<script>
import { getRiskRecordTrend, getBaseRiskData, getDoctorAuthenticityTrend, getLiveTimeTrend, getHeadAverageTrend } from '@/apis/risk-control'
export default {
  name: 'BasicOverview',
  data() {
    return {
      // 基础数据
      baseData: {},
      // 风控趋势
      riskOpt: {
        title: {
          text: '风控趋势',
          subtext: '单位：次'
        }
      },
      // 医生真实度趋势
      doctorOpt: {
        title: {
          text: '医生真实度趋势',
          subtext: '单位：百分比'
        },
        yAxis: {
          axisLabel: {
            show: true,
            interval: 'auto',
            formatter: '{value}%'
          }
        },
        tooltip: {
          trigger: 'axis',
          formatter: '{b0}<br/>{a0}: {c0}%<br />{a1}: {c1}%'
        }
      },
      // 同视频学习频率
      videoOpt: {
        title: {
          text: '同视频学习频率',
          subtext: '单位：次'
        }
      },
      // 同题目回答频率
      topicOpt: {
        title: {
          text: '同题目回答频率',
          subtext: '单位：次'
        }
      },
      // 直播平均时长
      liveOpt: {
        title: {
          text: '直播平均时长',
          subtext: '单位：分钟'
        }
      }
    }
  },
  mounted() {
    this.getHeaderData()
    this.getDoctorData()
    this.getLiveData()

    // 风控趋势
    this.getRisk()
    // 题目
    this.getTopicData(6, 'topicOpt')
    // 视频
    this.getTopicData(5, 'videoOpt')
  },
  methods: {
    // 风险趋势
    async getRisk() {
      try {
        const res = await getRiskRecordTrend()
        // 处理数据为折线图
        const typeMap = {
          '特殊行为黑名单校验': '特殊行为黑名单校验',
          '分配邀请码给医生': '分配邀请码给医生',
          '医生年费控制': '医生年费控制'
        }
        this.formatOpt({ rootData: res, typeMap, targetDataName: 'riskOpt' })
      } catch (error) {
        console.log(error)
      }
    },
    // 基础数据
    async getHeaderData() {
      try {
        const res = await getBaseRiskData()
        this.baseData = res || {}
      } catch (error) {
        console.log(error)
      }
    },
    // 医生真实数据
    async getDoctorData() {
      try {
        let res = await getDoctorAuthenticityTrend()
        // 处理数据为折线图
        const typeMap = {
          'certifyRating': '身份认证率',
          'realNameRating': '实名认证率'
        }
        res = res.map(item => {
          item.certifyRating = this.toPercent(item.certifyRating)
          item.realNameRating = this.toPercent(item.realNameRating)
          return item
        })
        this.formatOpt({ rootData: res, typeMap, targetDataName: 'doctorOpt' })
      } catch (error) {
        console.log(error)
      }
    },
    // 直播平均时长
    async getLiveData() {
      try {
        let res = await getLiveTimeTrend()
        // 处理数据为折线图
        const typeMap = {
          'liveTime': '直播推流时长',
          'liveViewTime': '直播观看时长'
        }
        res = res.map(item => {
          item.liveTime = (item.liveTime / 60).toFixed(2)
          item.liveViewTime = (item.liveViewTime / 60).toFixed(2)
          return item
        })
        this.formatOpt({ rootData: res, typeMap, targetDataName: 'liveOpt' })
      } catch (error) {
        console.log(error)
      }
    },
    // 头部平均数变化趋势 /重复视频，重复题目，月平均收入
    async getTopicData(type, targetDataName) {
      try {
        const res = await getHeadAverageTrend({ type })
        // 处理数据为折线图
        const typeMap = {
          'average': '平均值',
          'head': '头部数据'
        }
        this.formatOpt({ rootData: res, typeMap, targetDataName })
      } catch (error) {
        console.log(error)
      }
    },
    /**
         * 格式化折线图数据
         * rootData 接口返回的根数据（Array）
         * typeMap 映射数据 （Object）
         * targetDataName 目标数据名 （String）
         **/
    formatOpt({ rootData = [], typeMap, targetDataName, type = 'line' }) {
      try {
        let xArr = []
        let seriesArr = []
        for (const key in typeMap) {
          const obj = { name: typeMap[key], type, data: [] }
          xArr = rootData.map(currentValue => {
            obj.data.push(currentValue[key])
            return currentValue.dateStr
          })
          seriesArr = [...seriesArr, obj]
        }
        this.$set(this[targetDataName], 'xAxis', { data: xArr })
        this.$set(this[targetDataName], 'series', seriesArr)
      } catch (error) {
        console.log('formatOpt', error)
      }

    },
    // 处理html渲染的数据
    trueness() {
      const { doctorCertifyRating = 0, realNameRating = 0, doctorCount = 0 } = this.baseData
      return Math.floor((doctorCertifyRating + realNameRating) / doctorCount * 10000) / 100 || 0
    },
    // 小数转百分数
    toPercent(value) {
      return `${parseFloat((value * 100).toPrecision(12))}`
    },
    // 格式化数据
    toFixed(target) {
      return Math.ceil(target) || 0
      // return (Math.round(target * Math.pow(10, len)) / Math.pow(10, len)) || 0
    }
  }
}
</script>

<style lang="scss" scoped>
//scss
.grid-content {
  border-radius: 4px;
  background: #fff;
  box-shadow: 2px 2px 4px 0 rgba(234, 236, 240, 0.5);
  box-sizing: border-box;
}
.highlight {
  color: #309890;
  font-weight: 500;
  margin-left: 5px;
}
.header-wrap {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  gap: 20px;
}
.basis {
  .grid-content {
    padding: 15px 20px;
    .title {
      font-size: 36px;
      line-height: 50px;
      font-weight: bold;
    }
    .sub-title {
      color: #7e8caa;
      line-height: 30px;
      margin-top: 15px;
      font-size: 14px;
      .label {
        margin-right: 10px;
      }
    }
  }
}
.chart {
  .row-bg {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
    gap: 15px;
    .grid-content {
      height: 360px;
      > div {
        width: 100%;
        height: 100%;
        padding: 15px;
      }
    }
  }
}
</style>
