<template>
  <!-- 风控管理-风控记录 -->
  <div class="page-main">
    <!-- 功能按钮区 -->
    <div class="btn-wrap">
      <div class="title">
        {{ $route.meta.title }}
      </div>
    </div>
    <!-- 搜索区域 -->
    <search-wrap>
      <el-form
        v-model="search"
        inline
        label-suffix="："
      >
        <el-form-item
          label="手机号"
          prop="mobile"
        >
          <el-input
            v-model.trim="search.mobile"
            :maxlength="11"
            placeholder="请输入手机号"
          />
        </el-form-item>
        <el-form-item
          label="系统检查结果"
          prop="checkResult"
        >
          <el-input
            v-model.trim="search.checkResult"
            placeholder="请输入系统检查结果"
          />
        </el-form-item>
        <el-form-item
          label="用户类型"
          prop="userType"
        >
          <el-select
            v-model="search.userType"
            clearable
            filterable
            placeholder="请选择用户类型"
          >
            <el-option
              v-for="item in userTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          label="风控场景"
          prop="sceneCode"
        >
          <el-select
            v-model="search.sceneCode"
            clearable
            filterable
            placeholder="请选择风控场景"
          >
            <el-option
              v-for="item in sceneList"
              :key="item.value"
              :label="item.name"
              :value="item.code"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            plain
            @click="queryData"
          >
            搜索
          </el-button>
        </el-form-item>
      </el-form>
    </search-wrap>
    <!-- 处理表格区 -->
    <!-- <div class="handle-btn">
            <el-button type="primary" size="small" icon="el-icon-plus" plain @click="onAdd">新增</el-button>
        </div> -->
    <!-- 表格 -->
    <el-table
      ref="table"
      v-loading="loading"
      class="list-table"
      :data="tableData"
      border
      stripe
      highlight-current-row
      @sort-change="onSortChange"
    >
      <el-table-column
        prop="checkResult"
        label="系统检查结果"
      />
      <el-table-column
        prop="userId"
        label="用户ID"
        width="120"
      >
        <template slot-scope="scoped">
          <span @click="handleCopyLink(scoped.row,'userId')">{{ scoped.row.userId }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="userName"
        label="用户名"
        width="100"
      />
      <el-table-column
        prop="mobile"
        label="手机号"
        width="110"
      />
      <el-table-column
        prop="userType"
        label="用户类型"
        width="80"
      >
        <template slot-scope="scoped">
          {{ scoped.row.userType === 1 ?'医生':scoped.row.userType === 2 ?'代表':scoped.row.userType === 3 ?'厂家':scoped.row.userType === 4 ?'患者':'' }}
        </template>
      </el-table-column>
      <el-table-column
        prop="accessDesc"
        label="调用描述"
        width="230"
      />
      <el-table-column
        prop="dealRemark"
        label="处理意见"
        show-overflow-tooltip
      />
      <el-table-column
        prop="createdAt"
        label="风控时间"
      />
      <el-table-column
        label="操作"
        width="200"
        align="center"
      >
        <template slot-scope="scoped">
          <el-button
            type="primary"
            size="mini"
            plain
            @click="onCheck(scoped.row)"
          >
            查看详情
          </el-button>
          <!-- <el-button v-if="scoped.row.userType === 1" type="primary" size="mini" plain @click="onGiftChange(scoped.row)">相关数据</el-button> -->
          <el-button
            type="warning"
            size="mini"
            plain
            @click="onEdit(scoped.row)"
          >
            审核
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination-wrap">
      <el-pagination
        center
        :current-page="pagination.pageNo"
        :total="pagination.total"
        :page-size="pagination.pageSize"
        :page-sizes="pagination.sizes"
        :layout="pagination.layout"
        :hide-on-single-page="false"
        background
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      />
    </div>

    <!-- 弹窗区 -->

    <!-- 编辑和新增 -->
    <el-dialog
      title="审核"
      :visible.sync="editForm.dialogVisible"
      width="40%"
      @closed="onClosed"
    >
      <el-form
        ref="editForm"
        :model="editForm"
        label-suffix="："
        inline
        :rules="editFormRules"
      >
        <el-form-item
          label="处理备注"
          prop="remark"
        >
          <el-input
            v-model="editForm.remark"
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4}"
            class="textarea"
            placeholder="请输入处理备注"
          />
        </el-form-item>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="editForm.dialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          :loading="editForm.loading"
          @click="editFormSubmit"
        >确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import paginationMixin from '@/mixins/pagination'
import { recordList, dealRecord, getSceneList } from '@/apis/risk-control'
export default {
  name: 'Deal',
  mixins: [paginationMixin],
  data() {
    return {
      search: {
        mobile: '',
        userType: '',
        sceneCode: '',
        checkResult: ''
      },
      loading: false,
      tableData: [],
      sceneList: [],
      userTypeList: [{
        value: 1,
        label: '医生'
      }, {
        value: 2,
        label: '代表'
      }, {
        value: 3,
        label: '厂家'
      }, {
        value: 4,
        label: '患者'
      }],
      editForm: {
        id: '',
        remark: '',
        loading: false,
        dialogVisible: false
      },
      editFormRules: {
        remark: [{ required: true, message: '请输入备注', trigger: 'blur' }]
      }
    }
  },
  computed: {
    editTitle() {
      return this.editForm.id ? '编辑' : '新增'
    }
  },
  mounted() {
    this.getDataList()
    this.getScene()
  },
  methods: {
    // 获取列表数据
    async getDataList() {
      try {
        const params = this.getParams(this.search)
        this.loading = true
        const { data, totalCount } = await recordList(params)
        this.loading = false
        this.tableData = data
        this.pagination.total = totalCount
      } catch (error) {
        this.loading = false
      }
    },
    async getScene() {
      try {
        const { data } = await getSceneList({ pageNo: 1, pageSize: 999 })
        this.sceneList = data
      } catch (error) {
        console.log(error)
      }
    },
    handleCopyLink(row, type) {
      const _this = this
      let copyText = ''
      if (type == 'checkResult') {
        copyText = row[type].split('：')[1]
      } else {
        copyText = row[type]
      }
      this.$copyText(copyText).then(function() {
        _this.$message({
          message: `复制成功${type}: ${copyText}`,
          type: 'success'
        })
      }, function() {
        _this.$message({
          showClose: true,
          message: '复制失败，请手动复制',
          type: 'error'
        })
      })
    },
    // 表单提交
    editFormSubmit() {
      this.$refs['editForm'].validate(async valid => {
        if (valid) {
          this.editForm.loading = true
          const params = Object.assign({}, this.editForm)
          try {
            const res = await dealRecord(params)
            if (res == true) {
              this.getDataList()
              this.$message({ type: 'success', message: '审核成功' })
            }
            this.editForm.loading = false
            this.editForm.dialogVisible = false
          } catch (error) {
            console.log(error)
            this.editForm.loading = false
            this.$message({
              type: 'error',
              message: error.message
            })
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 搜索
    queryData() {
      this.pagination = this.$options.data().pagination
      this.getDataList()
    },
    onGiftChange(row) {
      this.$router.push({
        name: 'GiftRecord',
        query: {
          id: row.userId
        }
      })
    },
    // 编辑
    onEdit(row) {
      this.editForm = { ...row, dialogVisible: true }
    },
    // 查看
    onCheck(row) {
      this.$router.push({
        name: 'DealDealList',
        query: {
          id: row.id,
          userId: row.userId
        }
      })
    },
    onClosed() {
      this.resetForm()
    },
    resetForm() {
      this.editForm = this.$options.data().editForm
      this.$refs.editForm.resetFields()
    }
  }
}
</script>
<style lang="scss" scoped>
// scss
.btn-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  .title {
    font-size: 22px;
    font-weight: 500;
  }
}
.handle-btn {
  margin-bottom: 10px;
}
.textarea {
  width: 400px;
}
.pagination-wrap {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
</style>