<template>
  <!-- 风控管理-场景管理 -->
  <div class="page-main">
    <!-- 标题区 -->
    <div class="btn-wrap">
      <div class="title">
        {{ $route.meta.title }}
      </div>
    </div>
    <!-- 搜索区域 -->
    <search-wrap>
      <el-form
        v-model="search"
        inline
        label-suffix="："
      >
        <el-form-item
          label="场景名称"
          prop="name"
        >
          <el-input
            v-model="search.name"
            placeholder="请输入场景名称"
          />
        </el-form-item>
        <el-form-item
          label="状态"
          prop="status"
        >
          <el-radio-group
            v-model="search.status"
            @change="queryData"
          >
            <el-radio-button :label="2">
              全启用
            </el-radio-button>
            <el-radio-button :label="0">
              未启用
            </el-radio-button>
            <el-radio-button :label="1">
              局部启用
            </el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            plain
            @click="queryData"
          >
            搜索
          </el-button>
        </el-form-item>
      </el-form>
    </search-wrap>

    <!-- 处理表格区 -->
    <HandleBtnWrap>
      <template slot="left">
        <el-button
          type="primary"
          icon="el-icon-plus"
          plain
          @click="onAdd"
        >
          新增
        </el-button>
      </template>
      <template slot="right">
        <el-button
          slot="reference"
          plain
          type="primary"
          @click="onSyncRuleConfig"
        >
          重载规则
        </el-button>
        <!-- <el-popconfirm confirm-button-text="确定" cancel-button-text="取消" icon="el-icon-info" icon-color="red" title="确定重载规则么？" >
                    
                </el-popconfirm> -->
      </template>
    </HandleBtnWrap>
    <!-- 表格 -->
    <el-table
      ref="table"
      v-loading="loading"
      class="list-table"
      :data="tableData"
      border
      stripe
      highlight-current-row
      @sort-change="onSortChange"
    >
      <el-table-column
        prop="name"
        label="场景名称"
        :show-overflow-tooltip="true"
      />
      <el-table-column
        prop="code"
        label="code"
        width="200"
      />
      <el-table-column
        prop="status"
        label="状态"
        width="100"
      >
        <template slot-scope="scoped">
          {{ scoped.row.status === 0?'未启用':scoped.row.status === 1?'局部启用':scoped.row.status === 2?'全启用':'' }}
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        width="310"
        align="center"
      >
        <template slot-scope="scoped">
          <el-button
            v-if="scoped.row.status !== 1"
            slot="reference"
            type="primary"
            size="mini"
            plain
            @click="onUpAndDOwn(scoped.row,1)"
          >
            内部测试
          </el-button>
          <el-button
            v-if="scoped.row.status !== 2"
            slot="reference"
            type="primary"
            size="mini"
            plain
            @click="onUpAndDOwn(scoped.row,2)"
          >
            启用
          </el-button>
          <el-button
            v-if="scoped.row.status !== 0"
            slot="reference"
            type="danger"
            size="mini"
            plain
            @click="onUpAndDOwn(scoped.row,0)"
          >
            停用
          </el-button>
          <el-button
            type="warning"
            size="mini"
            plain
            @click="onEdit(scoped.row)"
          >
            编辑
          </el-button>
          <el-button
            type="success"
            size="mini"
            plain
            @click="onCheck(scoped.row)"
          >
            查看
          </el-button>
          <!-- <el-button type="danger" size="mini" plain @click="onDel(scoped.row)">删除</el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination-wrap">
      <el-pagination
        center
        :current-page="pagination.pageNo"
        :total="pagination.total"
        :page-size="pagination.pageSize"
        :page-sizes="pagination.sizes"
        :layout="pagination.layout"
        :hide-on-single-page="false"
        background
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      />
    </div>

    <!-- 弹窗区 -->

    <!-- 编辑和新增 -->
    <el-dialog
      :title="editTitle"
      :visible.sync="editForm.dialogVisible"
      width="40%"
      @closed="onClosed"
    >
      <el-form
        ref="editForm"
        :model="editForm"
        label-suffix="："
        label-width="120px"
        :rules="editFormRules"
      >
        <el-row>
          <el-col :span="22">
            <el-form-item
              label="状态"
              prop="status"
            >
              <el-radio-group v-model="editForm.status">
                <el-radio :label="0">
                  未启用
                </el-radio>
                <el-radio :label="1">
                  局部启用
                </el-radio>
                <el-radio :label="2">
                  全启用
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="22">
            <el-form-item
              label="场景名称"
              prop="name"
            >
              <el-input
                v-model="editForm.name"
                placeholder="请输入场景名称"
              />
            </el-form-item>
          </el-col>
          <el-col :span="22">
            <el-form-item
              label="code"
              prop="code"
            >
              <el-input
                v-model="editForm.code"
                :disabled="editForm.id?true:false"
                placeholder="请输入code"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="editForm.dialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          :loading="editForm.loading"
          @click="editFormSubmit"
        >确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import paginationMixin from '@/mixins/pagination'
import { getSceneList, saveScene, changeSceneStatus, syncRuleConfig } from '@/apis/risk-control'
export default {
  name: 'Scene',
  mixins: [paginationMixin],
  data() {
    return {
      search: {
        name: '',
        status: 2
      },
      loading: false,
      tableData: [],
      editForm: {
        id: '',
        status: 0,
        name: '',
        code: '',
        loading: false,
        dialogVisible: false
      },
      editFormRules: {
        name: [{ required: true, message: '请输入场景名称', trigger: 'blur' }],
        status: [{ required: true, message: '请选择状态', trigger: 'blur' }],
        code: [{ required: true, message: '请输入code', trigger: 'blur' }]
      }
    }
  },
  computed: {
    editTitle() {
      return this.editForm.id ? '编辑' : '新增'
    }
  },
  mounted() {
    this.getDataList()
  },
  methods: {
    // 获取列表数据
    async getDataList() {
      try {
        const params = this.getParams(this.search)
        this.loading = true
        const { data, totalCount } = await getSceneList(params)
        this.loading = false
        this.tableData = data
        this.pagination.total = totalCount
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    },
    // 重载规则
    onSyncRuleConfig() {
      this.$confirm('确定重载规则么？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        try {
          const res = await syncRuleConfig()
          if (res) this.$message({ type: 'success', message: '重载成功！' })
        } catch (error) {
          console.log(error)
          this.$message({ type: 'error', message: error.message })
        }
      })
    },
    // 表单提交
    editFormSubmit() {
      this.$refs['editForm'].validate(async valid => {
        if (valid) {
          this.editForm.loading = true
          const params = Object.assign({}, this.editForm)
          try {
            let msg = ''
            const res = await saveScene(params)
            msg = params.id ? '编辑' : '新增'
            if (res == true) {
              this.getDataList()
              this.$message({ type: 'success', message: `${msg}成功！` })
            }
            this.editForm.loading = false
            this.editForm.dialogVisible = false
          } catch (error) {
            console.log(error)
            this.editForm.loading = false
            this.$message({
              type: 'error',
              message: error.message
            })
          }
        } else {
          return false
        }
      })
    },
    // 搜索
    queryData() {
      this.pagination = this.$options.data().pagination
      this.getDataList()
    },
    // 新增
    onAdd() {
      this.editForm.dialogVisible = true
    },
    // 停用、启用、内部测试
    async onUpAndDOwn(row, status) {
      const { id } = row
      const res = await changeSceneStatus({ id, status })
      const message = status === 0 ? '停用' : status === 1 ? '内部测试' : status === 1 ? '启用' : ''
      if (res) {
        this.$message({ type: 'success', message: `${message}成功！` })
        this.getDataList()
      }
    },
    // 查看
    onCheck({ id, name, code }) {
      this.$router.push({
        name: 'CheckList',
        query: { id, name, code }
      })
    },
    // 编辑
    onEdit(item) {
      this.editForm = { ...item, dialogVisible: true }
    },
    // 删除
    onDel() {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    onClosed() {
      this.resetForm()
    },
    resetForm() {
      this.editForm = this.$options.data().editForm
      this.$refs.editForm.resetFields()
    }
  }
}
</script>

<style lang="scss" scoped>
// scss
.btn-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  .title {
    font-size: 22px;
    font-weight: 500;
  }
}
.handle-btn {
  margin-bottom: 10px;
}
.pagination-wrap {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
</style>