import API from './api-types'
import request from '@/utils/request'

export function getSceneList(params) {
  return request({
    url: API.getSceneList,
    method: 'get',
    params
  })
}
export function saveScene(data) {
  return request({
    url: API.saveScene,
    method: 'post',
    data
  })
}
export function changeSceneStatus(params) {
  return request({
    url: API.changeSceneStatus,
    method: 'get',
    params
  })
}
export function sceneRuleList(params) {
  return request({
    url: API.sceneRuleList,
    method: 'get',
    params
  })
}
export function saveRule(data) {
  return request({
    url: API.saveRule,
    method: 'post',
    data
  })
}
export function deleteRule(params) {
  return request({
    url: API.deleteRule,
    post: 'get',
    params
  })
}
export function changeRuleStatus(params) {
  return request({
    url: API.changeRuleStatus,
    method: 'get',
    params
  })
}

export function recordList(params) {
  return request({
    url: API.recordList,
    method: 'get',
    params
  })
}
export function dealRecord(params) {
  return request({
    url: API.dealRecord,
    methos: 'get',
    params
  })
}
export function specialUserList(params) {
  return request({
    url: API.specialUserList,
    method: 'get',
    params
  })
}
export function deleteSpecialUser(params) {
  return request({
    url: API.deleteSpecialUser,
    method: 'get',
    params
  })
}
// 等级列表
export function specialLevelList(params) {
  return request({
    url: API.specialLevelList,
    method: 'get',
    params
  })
}
// 风控目标列表
export function specialTargetList(params) {
  return request({
    url: API.specialTargetList,
    method: 'get',
    params
  })
}
export function saveSpecialUser(data) {
  return request({
    url: API.saveSpecialUser,
    method: 'post',
    data
  })
}

export function giftRecordList(params) {
  return request({
    url: API.giftRecordList,
    method: 'get',
    params
  })
}

export function syncRuleConfig(params) {
  return request({
    url: API.syncRuleConfig,
    method: 'get',
    params
  })
}
export function recordContextData(params) {
  return request({
    url: API.recordContextData,
    method: 'get',
    params
  })
}
export function speakerFeeList(params) {
  return request({
    url: API.speakerFeeList,
    method: 'get',
    params
  })
}
export function getOrgRiskData(params) {
  return request({
    url: API.getOrgRiskData,
    method: 'get',
    params
  })
}
export function getRiskRecordTrend(params) {
  return request({
    url: API.getRiskRecordTrend,
    method: 'get',
    params
  })
}
export function getDoctorIncomeRank(params) {
  return request({
    url: API.getDoctorIncomeRank,
    method: 'get',
    params
  })
}
export function getHeadAverageTrend(params) {
  return request({
    url: API.getHeadAverageTrend,
    method: 'get',
    params
  })
}
export function getDoctorAuthenticityTrend(params) {
  return request({
    url: API.getDoctorAuthenticityTrend,
    method: 'get',
    params
  })
}
export function getBaseRiskData(params) {
  return request({
    url: API.getBaseRiskData,
    method: 'get',
    params
  })
}
export function getLiveTimeTrend(params) {
  return request({
    url: API.getLiveTimeTrend,
    method: 'get',
    params
  })
}
export function getDoctorFactory(params) {
  return request({
    url: API.getDoctorFactory,
    method: 'get',
    params
  })
}

export function blockRecordList(params) {
  return request({
    url: API.blockRecordList,
    method: 'get',
    params
  })
}
export function reviewBlockRecord(params) {
  return request({
    url: API.reviewBlockRecord,
    method: 'post',
    data: params
  })
}
export function blockRecorddetail(params) {
  return request({
    url: API.blockRecorddetail,
    method: 'get',
    params
  })
}
