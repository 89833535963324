<template>
  <!-- 风控管理-风控记录 -->
  <Wrapper
    class="page-main"
    title="风控记录上下文数据"
  >
    <el-form
      ref="contextForm"
      :model="contextForm"
      label-suffix="："
      label-width="120px"
      inline
      label-position="left"
    >
      <el-row>
        <el-col :span="6">
          <el-form-item
            v-if="contextForm.thisYearGet"
            label="本年累计得积分"
            prop="thisYearGet"
          >
            {{ contextForm.thisYearGet }}
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item
            v-if="contextForm.willGet"
            label="将要获得"
            prop="willGet"
          >
            {{ contextForm.willGet }}
          </el-form-item>
        </el-col>
      </el-row>
      <!-- 医生信息 -->
      <el-card
        v-if="Object.keys(contextForm.doctorInfo || {}).length !== 0"
        class="box-card"
        shadow="never"
      >
        <div
          slot="header"
          class="clearfix"
        >
          <span>医生信息</span>
        </div>
        <el-row>
          <el-col :span="4">
            <el-form-item
              label="讲者认证状态"
              prop="speakerStatus"
            >
              {{ speakStatusFilter(contextForm.doctorInfo.speakerStatus) }}
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item
              label="身份认证状态"
              prop="certifyStatus"
            >
              {{ certStatusFilter(contextForm.doctorInfo.certifyStatus) }}
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item
              label="实名认证状态"
              prop="realNameAuthStatus"
            >
              {{ realStatusFilter(contextForm.doctorInfo.realNameAuthStatus) }}
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item
              label="医生等级"
              prop="doctorCreditRating"
            >
              {{ contextForm.doctorInfo.doctorCreditRating }}
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
      <!-- 代表信息 -->
      <el-card
        v-if="Object.keys(contextForm.representInfo || {}).length !== 0"
        class="box-card"
        shadow="never"
      >
        <div
          slot="header"
          class="clearfix"
        >
          <span>代表信息</span>
        </div>
        <!-- 团队关系 -->
        <CardItem
          v-if="Object.keys(contextForm.representInfo.teamRelations || {}).length !== 0"
          label="团队关系"
        >
          <el-row>
            <el-col :span="6">
              <el-form-item
                label="名称"
                prop="name"
              >
                {{ contextForm.representInfo.teamRelations.name }}
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item
                label="绑定状态"
                prop="status"
              >
                {{ bindStatus(contextForm.representInfo.teamRelations.status) }}
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item
                label="绑定时间"
                prop="bindAt"
              >
                {{ dateFormat(contextForm.representInfo.teamRelations.bindAt) }}
              </el-form-item>
            </el-col>
          </el-row>
        </CardItem>
        <!-- 组织关系 -->
        <CardItem
          v-if="Object.keys(contextForm.representInfo.orgRelations || {}).length !== 0"
          label="组织关系"
        >
          <el-row>
            <el-col :span="6">
              <el-form-item
                label="名称"
                prop="name"
              >
                {{ contextForm.representInfo.orgRelations.name }}
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item
                label="绑定状态"
                prop="status"
              >
                {{ bindStatus(contextForm.representInfo.orgRelations.status) }}
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item
                label="绑定时间"
                prop="bindAt"
              >
                {{ dateFormat(contextForm.representInfo.orgRelations.bindAt) }}
              </el-form-item>
            </el-col>
          </el-row>
        </CardItem>
      </el-card>
      <!-- 存在重合的观看记录 -->
      <el-card
        v-if="Object.keys(contextForm.viewRecords || {}).length !== 0"
        class="box-card"
        shadow="never"
      >
        <div
          slot="header"
          class="clearfix"
        >
          <span>存在重合的观看记录</span>
        </div>
        <el-row>
          <el-col :span="6">
            <el-form-item
              label="观看时长"
              prop="viewTime"
            >
              {{ dateFormat(contextForm.viewRecords.viewTime) }}
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item
              label="开始时间"
              prop="createdAt"
            >
              {{ dateFormat(contextForm.viewRecords.createdAt) }}
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item
              label="结束时间"
              prop="updatedAt"
            >
              {{ dateFormat(contextForm.viewRecords.updatedAt) }}
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
      <!-- 邀请码信息 -->
      <el-card
        v-if="Object.keys(contextForm.codeInfo || {}).length !== 0"
        class="box-card"
        shadow="never"
      >
        <div
          slot="header"
          class="clearfix"
        >
          <span>邀请码信息</span>
        </div>
        <el-row>
          <el-col :span="6">
            <el-form-item
              label="业务场景"
              prop="sceneType"
            >
              {{ sceneType(contextForm.codeInfo.sceneType) }}
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item
              label="标题"
              prop="certifyStatus"
            >
              {{ contextForm.codeInfo.sceneTitle }}
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item
              label="金额"
              prop="money"
            >
              {{ contextForm.codeInfo.money }}
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item
              label="流转记录"
              prop="actionRecord"
            >
              {{ contextForm.codeInfo.actionRecord }}
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item
              label="给代表的时间"
              prop="toRepresentTime"
            >
              {{ dateFormat(contextForm.codeInfo.toRepresentTime) }}
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item
              label="代表名"
              prop="representName"
            >
              {{ contextForm.codeInfo.representName }}
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item
              label="团队名"
              prop="teamName"
            >
              {{ contextForm.codeInfo.teamName }}
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item
              label="码状态"
              prop="codeStatus"
            >
              {{ codeStatus(contextForm.codeInfo.codeStatus) }}
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item
              label="业务状态"
              prop="sceneStatus"
            >
              {{ sceneStatus(contextForm.codeInfo.sceneStatus) }}
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item
              label="过期时间"
              prop="overdueAt"
            >
              {{ dateFormat(contextForm.codeInfo.overdueAt) }}
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item
              label="打开时间"
              prop="openAt"
            >
              {{ dateFormat(contextForm.codeInfo.openAt) }}
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item
              label="完成时间"
              prop="completeAt"
            >
              {{ dateFormat(contextForm.codeInfo.completeAt) }}
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item
              label="医生名"
              prop="doctorName"
            >
              {{ contextForm.codeInfo.doctorName }}
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item
              label="给医生的时间"
              prop="toDoctorTime"
            >
              {{ dateFormat(contextForm.codeInfo.toDoctorTime) }}
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
      <!-- 已答过的题 -->
      <el-card
        v-if="Object.keys(contextForm.answerExams || {}).length !== 0"
        class="box-card"
        shadow="never"
      >
        <div
          slot="header"
          class="clearfix"
        >
          <span>已答过的题</span>
        </div>
        <el-table
          :data="contextForm.answerExams"
          border
        >
          <el-table-column
            label="序号"
            align="center"
            type="index"
            width="50"
          />
          <el-table-column
            label="题干"
            prop="title"
          />
        </el-table>
      </el-card>
      <!-- 已邀请的码信息 -->
      <el-card
        v-if="contextForm.invitedCodeInfos.length !== 0"
        class="box-card"
        shadow="never"
      >
        <div
          slot="header"
          class="clearfix"
        >
          <span>已邀请的码信息</span>
        </div>
        <el-table
          ref="table"
          v-loading="loading"
          header-cell-class-name="list-table"
          :data="contextForm.invitedCodeInfos"
          border
          stripe
          highlight-current-row
        >
          <el-table-column
            prop="sceneType"
            label="业务场景"
            width="100"
          >
            <template slot-scope="scoped">
              {{ sceneType(scoped.row.sceneType) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="sceneTitle"
            width="200"
            label="标题"
          />
          <el-table-column
            prop="money"
            label="金额"
            width="50"
          />
          <el-table-column
            prop="actionRecord"
            width="200"
            label="流转记录"
          />
          <el-table-column
            prop="toRepresentTime"
            width="160"
            label="给代表的时间"
          >
            <template slot-scope="scoped">
              {{ dateFormat(scoped.row.toRepresentTime) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="representName"
            label="代表名"
            width="80"
          />
          <el-table-column
            prop="teamName"
            label="团队名"
          />
          <el-table-column
            prop="codeStatus"
            label="码状态"
            width="80"
          >
            <template slot-scope="scoped">
              {{ codeStatus(scoped.row.codeStatus) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="sceneStatus"
            label="业务状态"
            width="80"
          >
            <template slot-scope="scoped">
              {{ sceneStatus(scoped.row.sceneStatus) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="overdueAt"
            width="160"
            label="过期时间"
          >
            <template slot-scope="scoped">
              {{ dateFormat(scoped.row.overdueAt) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="openAt"
            width="160"
            label="打开时间"
          >
            <template slot-scope="scoped">
              {{ dateFormat(scoped.row.openAt) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="completeAt"
            width="160"
            label="完成时间"
          >
            <template slot-scope="scoped">
              {{ dateFormat(scoped.row.completeAt) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="doctorName"
            label="医生名"
          />
          <el-table-column
            prop="toDoctorTime"
            width="160"
            label="给医生的时间"
          >
            <template slot-scope="scoped">
              {{ dateFormat(scoped.row.toDoctorTime) }}
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </el-form>
  </Wrapper>
</template>

<script>
import { recordContextData } from '@/apis/risk-control'
import Wrapper from './Wrapper'
import CardItem from './CardItem.vue'
export default {
  name: 'ContextData',
  components: { Wrapper, CardItem },
  data() {
    return {
      loading: false,
      contextForm: {invitedCodeInfos: [], codeInfo: {}, doctorInfo: {}},
      search: {
        id: ''
      }
    }
  },
  computed: {
    answerSceneType() {
      let str = ''
      try {
        switch (this.contextForm.codeInfo.sceneType) {
          case 1:
            str = '科会'
            break
          case 2:
            str = '调研'
            break
          case 3:
            str = '拜访'
            break
          case 4:
            str = '问卷调研'
            break
          case 5:
            str = '单人直播'
            break
          case 6:
            str = '多人会议'
            break
          case 7:
            str = '代表培训'
            break
          case 8:
            str = '学术拜访'
            break
          default:
            break
        }
      } catch (error) {
        console.log(error)
      }
      return str
    }
  },
  mounted() {
    this.search.id = this.$route.query.id
    this.getDataList()
  },
  methods: {
    // 获取列表数据
    async getDataList() {
      try {
        const params = { ...this.search }
        this.loading = true
        const res = await recordContextData(params)
        this.contextForm = res || {invitedCodeInfos: [], codeInfo: {}, doctorInfo: {}}
        this.loading = false
      } catch (error) {
        this.loading = false
      }
    },

    /**
         * 处理数据
         **/
    // 展示业务场景
    sceneType(val) {
      try {
        switch (val) {
          case 1:
            return '科会'
          case 2:
            return '调研'
          case 3:
            return '拜访'
          case 4:
            return '问卷调研'
          case 5:
            return '单人直播'
          case 6:
            return '多人会议'
          case 7:
            return '代表培训'
          case 8:
            return '学术拜访'
          default:
            return ''
        }
      } catch (error) {
        console.log(error)
      }
    },
    codeStatus(val) {
      try {
        switch (val) {
          case 0:
            return '未使用'
          case 1:
            return '冻结'
          case 2:
            return '已撤回'
          case 3:
            return '已使用'
          default:
            return ''
        }
      } catch (error) {
        console.log(error)
      }
    },
    sceneStatus(val) {
      try {
        switch (val) {
          case 0:
            return '未打开'
          case 1:
            return '已打开'
          case 2:
            return '已完成'
          case 3:
            return '已过期'
          case 4:
            return '已答题'
          case 5:
            return '已掉落时长礼盒'
        }
      } catch (error) {
        console.log(error)
      }
    },
    // 绑定状态
    bindStatus(val) {
      try {
        switch (val) {
          case 0:
            return '解绑'
          case 1:
            return '绑定'
          default:
            return ''
        }
      } catch (error) {
        console.log(error)

      }
    },
    // 格式化时间
    dateFormat(val) {
      try {
        if (val) {
          return this.$dayjs(val).format('YYYY-MM-DD HH:mm:ss')
        } else {
          return ''
        }
      } catch (error) {
        console.log(error)
      }
    },
    // 讲者认证状态
    speakStatusFilter(val) {
      switch (val) {
        case 1:
          return '讲者认证完成'
        case 0:
          return '讲者认证审核中'
        case 2:
          return '讲者认证未通过'
        case -1:
          return '未讲者认证'
        default:
          return ''
      }
    },
    // 身份认证状态
    certStatusFilter(val) {
      switch (val) {
        case 1:
          return '工作证明认证完成'
        case 3:
          return '工作证明认证未通过'
        case 2:
          return '工作证明认证审核中'
        default:
          return '未认证'
      }
    },
    // 实名认证状态
    realStatusFilter(val) {
      switch (val) {
        case 0:
          return '未实名认证'
        case 1:
          return '实名认证完成'
        case 2:
          return '实名认证未通过'
        case 3:
          return '实名认证审核中'
        default:
          return ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
// scss
.pagination-wrap {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
:deep(.el-form-item__content:empty::before) {
  content: '/';
  color: rgb(94, 94, 94);
}
:deep(.el-form-item) {
  margin: 0;
}
.box-card {
  margin-bottom: 10px;
}
.title {
  margin: 20px 0;
  margin-left: 30px;
}
.sub-title {
  margin: 20px 0;
  margin-left: 60px;
}
</style>
